import { useEffect, useState } from "react"
import _ from "lodash"
import { Switch, Transition } from "@headlessui/react"
import { PencilIcon, TrashIcon, CheckIcon } from "@heroicons/react/solid"

import { ProductOption, ProductOptionGroup } from "../../../../../lib/GDAPI"
import { TextField } from "../../../../common/TextField"
import { usePrevious } from "../../../../../hooks/UsePrevious"

type ProductOptionsFieldProps = {
  optionGroups?: ProductOptionGroup[]
  disabled?: boolean
  onOptionGroupsChanged: (optionGroups?: ProductOptionGroup[]) => any
}

export function ProductOptionsField({ optionGroups, disabled, onOptionGroupsChanged }: ProductOptionsFieldProps) {
  const isEnabled = optionGroups != null
  const [selectedGroupKey, setSelectedGroupKey] = useState<string>()
  const previousOptionGroups = usePrevious(optionGroups)

  return (
    <div className="border-t border-b border-blue-100 py-4 flex flex-col">
      <Switch.Group>
        <div className="flex items-center">
          <Switch.Label className="flex-1 cursor-pointer pr-4">
            <div className="text-base font-medium text-blue-500">
              Customisable options
            </div>
            <div className="text-sm font-regular text-gray-500 leading-4 mt-1">
              Create options for this product that customers can customise.
            </div>
          </Switch.Label>
          <Switch
            as="div"
            checked={isEnabled}
            onChange={enabled => {
              if (disabled) {
                return
              }
              
              if (optionGroups != null) {
                onOptionGroupsChanged(undefined)
              } else {
                onOptionGroupsChanged(previousOptionGroups ?? [])
              }
            }}
            className={`
              ${isEnabled ? "bg-blue-500" : "bg-gray-200"}
              relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
            `}
          >
            <span
              className={`
                ${isEnabled ? "translate-x-7" : "translate-x-1"}
                inline-block w-6 h-6 bg-white rounded-full
                transform transition ease-in-out duration-200 shadow
              `}
            />
          </Switch>
        </div>
      </Switch.Group>
      <Transition
        show={isEnabled}
        enter="transition-opacity ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-col mt-2">
          {optionGroups?.filter(group => group.id != null).map((group, index) => (
            <ProductOptionGroupItem
              key={group.id}
              index={index + 1}
              optionGroup={group}
              isEditing={group.id === selectedGroupKey}
              onEditPressed={() => setSelectedGroupKey(group.id)}
              onSavePressed={() => setSelectedGroupKey(undefined)}
              onGroupUpdated={(updatedOptionGroup) => {
                const updatedGroups = [...optionGroups]
                updatedGroups[index] = updatedOptionGroup
                onOptionGroupsChanged(updatedGroups)
              }}
              onDeletePressed={() => {
                const updatedGroups = optionGroups.filter((otherGroup, groupIndex) => otherGroup.id !== group.id)
                onOptionGroupsChanged(updatedGroups)
                setSelectedGroupKey(undefined)
              }}
              canMoveUp={index > 0}
              canMoveDown={index < (optionGroups.length - 1)}
              onMoveUpPressed={() => {
                const updatedGroups = [...optionGroups]
                updatedGroups[index] = updatedGroups[index - 1]
                updatedGroups[index - 1] = group

                onOptionGroupsChanged(updatedGroups)
              }}
              onMoveDownPressed={() => {
                const updatedGroups = [...optionGroups]
                updatedGroups[index] = updatedGroups[index + 1]
                updatedGroups[index + 1] = group

                onOptionGroupsChanged(updatedGroups)
              }}
            />
          ))}
          <button
            className="
              flex-1
              flex flex-row items-center justify-center
              py-2 px-4
              bg-blue-200
              border border-transparent
              rounded-lg shadow mt-2
            "
            onClick={(event) => {
              event.preventDefault()

              const id = `${Date.now()}_${Math.random()}`
              const updatedGroups: ProductOptionGroup[] = [
                ...optionGroups!,
                {
                  name: "",
                  included: 1,
                  extras: 0,
                  options: [],
                  id
                }
              ]

              onOptionGroupsChanged(updatedGroups)
              setSelectedGroupKey(id)
            }}
          >
            <div
              className="text-base font-medium leading-none text-blue-800"
            >
              Create an option set
            </div>
          </button>
        </div>
      </Transition>
    </div>
  )
}

type ProductOptionGroupItemProps = {
  index: number
  optionGroup?: ProductOptionGroup
  disabled?: boolean
  isEditing: boolean
  onEditPressed: () => any
  onSavePressed: () => any
  onDeletePressed: () => any
  onGroupUpdated: (optionGroup: ProductOptionGroup) => any
  canMoveUp: boolean
  onMoveUpPressed: () => any
  canMoveDown: boolean
  onMoveDownPressed: () => any
}

function ProductOptionGroupItem({
  index, optionGroup, disabled, isEditing, onEditPressed, onSavePressed, onDeletePressed, onGroupUpdated,
  canMoveUp, onMoveUpPressed, canMoveDown, onMoveDownPressed
}: ProductOptionGroupItemProps) {
  const [optionGroupNameValue, setOptionGroupNameValue] = useState(optionGroup?.name ?? "")
  const [optionGroupIncludedValue, setOptionGroupIncludedValue] = useState(optionGroup?.included?.toString() ?? "1")
  const [optionGroupExtrasValue, setOptionGroupExtrasValue] = useState(optionGroup?.extras?.toString() ?? "0")
  const [options, setOptions] = useState<ProductOption[]>(optionGroup?.options ?? [])

  useEffect(() => {
    const name = optionGroupNameValue.trim()
    let included = parseInt(optionGroupIncludedValue.trim())
    let extras = parseInt(optionGroupExtrasValue.trim())

    const updatedGroup: ProductOptionGroup = { name, included, extras, options, id: optionGroup?.id }

    if (_.isEqual(updatedGroup, optionGroup)) {
      return
    }

    onGroupUpdated(updatedGroup)
  }, [
    optionGroupNameValue, optionGroupIncludedValue, optionGroupExtrasValue, options,
    onGroupUpdated, optionGroup
  ])

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center py-2">
        {isEditing &&
          <button
            className="bg-red-100 p-2 rounded-full shadow flex flex-row items-center justify-center mr-2"
            onClick={(event) => {
              event.preventDefault()
              onDeletePressed()
            }}
          >
            <TrashIcon className="h-5 w-5 text-red-800" />
          </button>
        }
        <div className="text-base text-blue-500 font-medium leading-none mr-1">
          {index}.
        </div>
        {isEditing ? (
          <>
            <TextField
              name="Option name"
              hideLabel={true}
              value={optionGroupNameValue}
              onValueChanged={setOptionGroupNameValue}
              required
              disabled={disabled}
              className="flex-1"
              placeholder="Name"
            />
            <div className="flex flex-row items-center ml-2">
              <button
                className="bg-green-200 p-2 rounded-full shadow flex flex-row items-center justify-center"
                onClick={(event) => {
                  event.preventDefault()
                  onSavePressed()
                }}
              >
                <CheckIcon className="h-5 w-5 text-green-800" />
              </button>
            </div>
          </>
        ) : (
          <>
            <div className="flex-1 text-base text-blue-500 font-medium leading-none">
              {optionGroup?.name}
            </div>
            <div className="flex flex-row items-center ml-2">
              <button
                className="bg-yellow-200 p-2 rounded-full shadow flex flex-row items-center justify-center"
                onClick={(event) => {
                  event.preventDefault()
                  onEditPressed()
                }}
              >
                <PencilIcon className="h-5 w-5 text-yellow-800" />
              </button>
            </div>
          </>
        )}
      </div>
      {isEditing &&
        <div className="flex flex-row items-center">
          <button
            className={`
              flex-1
              flex flex-row items-center justify-center
              py-2 px-4
              bg-blue-200
              border border-transparent
              rounded-lg shadow mr-1
              ${canMoveUp ? "opacity-100" : "opacity-50"}
            `}
            disabled={!canMoveUp}
            onClick={(event) => {
              event.preventDefault()
              onMoveUpPressed()
            }}
          >
            <div
              className="text-base font-medium leading-none text-blue-800"
            >
              Move up
            </div>
          </button>
          <button
            className={`
              flex-1
              flex flex-row items-center justify-center
              py-2 px-4
              bg-blue-200
              border border-transparent
              rounded-lg shadow ml-1
              ${canMoveDown ? "opacity-100" : "opacity-30"}
            `}
            disabled={!canMoveDown}
            onClick={(event) => {
              event.preventDefault()
              onMoveDownPressed()
            }}
          >
            <div
              className="text-base font-medium leading-none text-blue-800"
            >
              Move down
            </div>
          </button>
        </div>
      }
      <div className="flex flex-row items-center py-2">
        <div className="flex-1 text-base text-gray-800 font-medium leading-none">
          Included choices:
        </div>
        {isEditing ? (
          <>
            <TextField
              name="Included choices"
              value={optionGroupIncludedValue}
              onValueChanged={setOptionGroupIncludedValue}
              type="number"
              min={0}
              disabled={disabled}
              className="w-16"
              hideLabel={true}
            />
          </>
        ) : (
          <>
            <div className="text-base text-black font-medium leading-none">
              {optionGroup?.included}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-row items-center py-2">
        <div className="flex-1 text-base text-gray-800 font-medium leading-none">
          Extra choices:
        </div>
        {isEditing ? (
          <>
            <TextField
              name="Extra choices"
              value={optionGroupExtrasValue}
              onValueChanged={setOptionGroupExtrasValue}
              type="number"
              min={0}
              disabled={disabled}
              className="w-16"
              hideLabel={true}
            />
          </>
        ) : (
          <>
            <div className="text-base text-black font-medium leading-none">
              {optionGroup?.extras}
            </div>
          </>
        )}
      </div>
      <div className="flex flex-row py-2">
        <div className="text-sm text-blue-500 font-medium leading-none" style={{ flex: 3 }}>
          Name
        </div>
        <div className="text-sm text-blue-500 font-medium leading-none ml-2 text-right" style={{ flex: 1 }}>
          Price
        </div>
        <div className="text-sm text-blue-500 font-medium leading-none ml-2 text-right" style={{ flex: 1 }}>
          Max
        </div>
      </div>
      {options.filter(option => option.id != null).map((option, index) => (
        <ProductOptionItem
          key={option.id}
          option={option}
          isEditing={isEditing}
          onOptionUpdated={(updatedOption) => {
            const updatedOptions = [...options]
            updatedOptions[index] = updatedOption
            setOptions(updatedOptions)
          }}
          onDeletePressed={() => {
            const updatedOptions = options.filter((otherOption, optionIndex) => otherOption.id !== option.id)
            setOptions(updatedOptions)
          }}
        />
      ))}
      {isEditing &&
        <button
          className="
            flex-1
            flex flex-row items-center justify-center
            py-2 px-4
            bg-green-200
            border border-transparent
            rounded-lg shadow my-2
          "
          onClick={(event) => {
            event.preventDefault()

            const updatedOptions: ProductOption[] = [
              ...options,
              {
                name: "",
                price: 0,
                max: 1,
                id: `${Date.now()}_${Math.random()}`
              }
            ]

            setOptions(updatedOptions)
          }}
        >
          <div
            className="text-base font-medium leading-none text-green-800"
          >
            Add an option
          </div>
        </button>
      }
    </div>
  )
}

type ProductOptionItemProps = {
  isEditing?: boolean
  option?: ProductOption
  onOptionUpdated: (option: ProductOption) => any
  onDeletePressed: () => any
}

function ProductOptionItem({ isEditing, option, onOptionUpdated, onDeletePressed }: ProductOptionItemProps) {
  const [nameValue, setNameValue] = useState(option?.name ?? "")
  const [priceValue, setPriceValue] = useState(option?.price.toString() ?? "0")
  const [maxValue, setMaxValue] = useState(option?.max.toString() ?? "1")

  useEffect(() => {
    const name = nameValue.trim()
    let price = parseInt(priceValue.trim())
    let max = parseInt(maxValue.trim())
    
    if (isNaN(price)) {
      price = 0
    }

    if (isNaN(max)) {
      max = 1
    }

    const updatedOption: ProductOption = { name, price, max, id: option?.id }

    if (_.isEqual(option, updatedOption)) {
      return
    }

    onOptionUpdated({ name, price, max, id: option?.id })
  }, [
    nameValue, priceValue, maxValue, onOptionUpdated, option
  ])

  return (
    <div className="flex flex-row py-2 items-center border-t border-gray-200">
      {isEditing ? (
        <>
          <button
            className="bg-red-100 p-2 rounded-full shadow flex flex-row items-center justify-center mr-2"
            onClick={(event) => {
              event.preventDefault()
              onDeletePressed()
            }}
          >
            <TrashIcon className="h-5 w-5 text-red-800" />
          </button>
          <TextField
            name="Choice name"
            value={nameValue}
            onValueChanged={setNameValue}
            hideLabel={true}
            placeholder="Option"
            style={{ flex: 3 }}
          />
          <TextField
            name="Choice price"
            value={priceValue}
            onValueChanged={setPriceValue}
            type="number"
            min={0}
            className="ml-2"
            hideLabel={true}
            style={{ flex: 1 }}
          />
          <TextField
            name="Max quantity"
            value={maxValue}
            onValueChanged={setMaxValue}
            type="number"
            min={0}
            className="ml-2"
            hideLabel={true}
            style={{ flex: 1 }}
          />
        </>
      ) : (
        <>
          <div className="text-base leading-none" style={{ flex: 4 }}>
            {option?.name}
          </div>
          <div className="text-base leading-none ml-2 text-right" style={{ flex: 1 }}>
            {option?.price ?? 0}
          </div>
          <div className="text-base leading-none ml-2 text-right" style={{ flex: 1 }}>
            {option?.max ?? 1}
          </div>
        </>
      )}
    </div>
  )
}

