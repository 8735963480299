import { useCallback } from "react"
import { useAsync } from "react-async"
import Loader from "react-loader-spinner"
import { useHistory, useRouteMatch } from "react-router-dom"
import { ArrowSmLeftIcon, PlusIcon, DocumentAddIcon, FolderAddIcon, SearchIcon } from "@heroicons/react/solid"

import { GDAPI, Product, ProductCategory, ProductCategorySummary } from "../../../lib/GDAPI"
import { ProductCategoryItem } from "./ProductCategoryItem"
import { ProductItem } from "./ProductItem"
import { CategoryAvailabilityLabel } from "./dialogs/category/CategoryAvailabilityLabel"
import { CategoryDialog } from "./dialogs/category/CategoryDialog"
import { useEffect, useRef, useState } from "react"
import { Popover } from "@headlessui/react"
import { usePopper } from "react-popper"
import { ProductDialog } from "./dialogs/product/ProductDialog"
import { TextField } from "../../common/TextField"
import _ from "lodash"

type PageData = {
  rootCategories?: ProductCategorySummary[]
  category?: ProductCategory
}

async function fetchPage(props: any, controller: AbortController): Promise<PageData | undefined> {
  const { id, isSearching } = props as { id?: string, isSearching: boolean }

  if (isSearching) {
    return
  }

  if (id != null) {
    return {
      category: await GDAPI.getProductCategory(id, controller)
    }
  } else {
    return {
      rootCategories: await GDAPI.getCategories(controller)
    }
  }
}

async function searchProducts(args: any[], props: any, controller: AbortController) {
  const [query] = args as [string]

  if (query.length < 2) {
    return []
  }

  return GDAPI.searchProducts(query, controller)
}

export function ProductsPage() {
  const routeMatch = useRouteMatch<{ path: string }>("/products/:path")
  const path = routeMatch?.params.path

  const isSearching = path === "search"
  let categoryId: number | undefined

  if (!isSearching && path != null) {
    const id = parseInt(path)

    if (!isNaN(id)) {
      categoryId = id
    }
  }

  const [searchValue, setSearchValue] = useState("")
  const searchInput = useRef<HTMLInputElement>(null)

  const history = useHistory()
  const pageRequest = useAsync({ promiseFn: fetchPage, id: categoryId, isSearching })
  const searchResults = useAsync({ deferFn: searchProducts })

  //eslint-disable-next-line
  const onSearchDebounced = useCallback(_.debounce(searchResults.run, 500), [searchResults])
  const onSearchValueChanged = useCallback((input: string) => {
    setSearchValue(input)

    const query = input.trim()

    if (query.length === 0) {
      onSearchDebounced("")
      onSearchDebounced.flush()
      return
    }

    onSearchDebounced(query)
  }, [setSearchValue, onSearchDebounced])

  useEffect(() => {
    if (!isSearching) {
      pageRequest.reload()
    }

    //eslint-disable-next-line
  }, [pageRequest.reload, isSearching, categoryId])

  const isPending = pageRequest.isPending || searchResults.isPending
  const requestError = pageRequest.error
  const rootCategories = pageRequest.data?.rootCategories
  const category = pageRequest.data?.category
  const title = category?.name ?? "Main categories"

  const [createButtonRef, setCreateButtonRef] = useState<HTMLButtonElement | null>(null)
  const [popperRef, setPopperRef] = useState<HTMLDivElement | null>(null)
  const popper = usePopper(createButtonRef, popperRef, { placement: "bottom-start" })

  const [isCreatingCategory, setCreatingCategory] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<ProductCategorySummary | undefined>()
  const showCategoryDialog = isCreatingCategory || selectedCategory != null

  const viewCategory = (category: ProductCategorySummary) => history.push(`/products/${category.id}`)
  const editCategory = (category: ProductCategorySummary) => setSelectedCategory(category)
  const closeCategoryDialog = () => isCreatingCategory ? setCreatingCategory(false) : setSelectedCategory(undefined)

  const [isCreatingProduct, setCreatingProduct] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Product | undefined>()
  const showProductDialog = isCreatingProduct || selectedProduct != null

  const editProduct = (product: Product) => {
    setSelectedProduct(product)
  }
  const closeProductDialog = () => {
    if (isCreatingProduct) {
      setCreatingProduct(false)
    } else {
      setSelectedProduct(undefined)
    }
  }

  let rootCategoryItems: JSX.Element[] | undefined
  let subcategoryItems: JSX.Element[] | undefined
  let productItems: JSX.Element[] | undefined

  if (isSearching) {
    productItems = searchResults.data?.map(product => (
      <ProductItem
        key={product.id}
        product={product}
        onEditPressed={editProduct}
      />
    ))
  } else {
    rootCategoryItems = rootCategories?.map(category => (
      <ProductCategoryItem
        key={category.id}
        category={category}
        onEditPressed={editCategory}
        onViewPressed={viewCategory}
      />
    ))

    subcategoryItems = category?.subcategories.map(category => (
      <ProductCategoryItem
        key={category.id}
        category={category}
        onEditPressed={editCategory}
        onViewPressed={viewCategory}
      />
    ))

    productItems = category?.products.map(product => (
      <ProductItem
        key={product.id}
        product={product}
        onEditPressed={editProduct}
      />
    ))
  }

  return (
    <>
      <CategoryDialog
        isOpen={showCategoryDialog}
        onClose={closeCategoryDialog}
        currentCategoryId={categoryId}
        currentMerchantId={category?.merchant ?? undefined}
        existingCategory={selectedCategory}
        onCategoryCreated={category => {
          if (isSearching) {
            searchResults.reload()
          } else {
            pageRequest.reload()
          }

          closeCategoryDialog()
        }}
        onCategoryUpdated={category => {
          if (isSearching) {
            searchResults.reload()
          } else {
            pageRequest.reload()
          }

          closeCategoryDialog()
        }}            
        onCategoryDeleted={() => {
          if (isSearching) {
            searchResults.reload()
          } else {
            pageRequest.reload()
          }

          closeCategoryDialog()
        }}
      />
      <ProductDialog
        isOpen={showProductDialog}
        onClose={closeProductDialog}
        existingProduct={selectedProduct}
        onProductCreated={product => {
          if (isSearching) {
            searchResults.reload()
          } else {
            pageRequest.reload()
          }

          closeProductDialog()
        }}
        onProductUpdated={product => {
          if (isSearching) {
            searchResults.reload()
          } else {
            pageRequest.reload()
          }

          closeProductDialog()
        }}
        onProductDeleted={() => {
          if (isSearching) {
            searchResults.reload()
          } else {
            pageRequest.reload()
          }

          closeProductDialog()
        }}
        currentCategoryId={categoryId}
      />
      <div className="flex-1 flex flex-col bg-white rounded shadow overflow-y-hidden">
        <div className="flex flex-col border-b border-gray-200 shadow-sm z-10">
          <div className="flex flex-row items-center py-4">
            {(isSearching || category != null) &&
              <button
                className="flex flex-row items-center justify-center p-2 bg-gray-200 rounded-full shadow ml-4 mr-2"
                onClick={() => {
                  if (isSearching) {
                    setSearchValue("")
                    searchResults.setData([])
                    history.push("/products")
                    return
                  }

                  const parentCategoryId = category?.parentCategory?.id
                  
                  if (parentCategoryId != null) {
                    history.push(`/products/${parentCategoryId}`)
                  } else {
                    history.push("/products")
                  }
                }}
              >
                <ArrowSmLeftIcon className="w-5 h-5 text-gray-800" />
              </button>
            }          
            {isSearching &&
              <>
                <TextField
                  name="Search"
                  placeholder="Type here to search"
                  type="string"
                  value={searchValue}
                  onValueChanged={onSearchValueChanged}
                  ref={searchInput}
                  hideLabel
                  className="flex-1 ml-2 mr-4"
                  primary
                  onSubmit={() => searchInput.current?.blur()}
                  autoComplete="off"
                />
              </>
            }
            {!isSearching &&
              <>
                <div className={`flex-1 flex flex-col mr-2 ${category == null ? "ml-4" : ""}`}>
                  {category != null &&
                    <div className="text-sm font-regular text-gray-500 leading-4">
                      {category?.parentCategory?.name ?? "Main categories"}
                    </div>
                  }
                  <div className="text-md font-medium text-brand-700 leading-5">
                    {title}
                  </div>
                </div>
                <button
                  className="bg-blue-200 p-2 rounded-full shadow mr-4 flex flex-row items-center justify-center"
                  onClick={() => history.push("/products/search")}
                >
                  <SearchIcon className="h-5 w-5 text-blue-800" />
                </button>
                <Popover className="relative">
                  <Popover.Button
                    ref={setCreateButtonRef}
                    className="bg-green-200 p-2 rounded-full shadow mr-4 flex flex-row items-center justify-center"
                  >
                    <PlusIcon className="h-5 w-5 text-green-800" />
                  </Popover.Button>

                  <Popover.Panel
                    ref={setPopperRef}
                    style={popper.styles.popper}
                    {...popper.attributes.popper}
                  >
                    <div className="flex flex-col border border-gray-300 overflow-hidden bg-white rounded-lg shadow-md w-48">
                      <button
                        className="flex flex-row items-center p-3 hover:bg-gray-100 border-b border-gray-300"
                        onClick={() => {
                          createButtonRef?.click()
                          setCreatingCategory(true)
                        }}
                      >
                        <FolderAddIcon className="w-5 h-5 text-gray-600" />
                        <div className="text-sm text-gray-600 ml-2">
                          Create a category  
                        </div>
                      </button>
                      <button
                        className="flex flex-row items-center p-3 hover:bg-gray-100"
                        onClick={() => {
                          createButtonRef?.click()
                          setCreatingProduct(true)
                        }}
                      >
                        <DocumentAddIcon className="w-5 h-5 text-gray-600" />
                        <div className="text-sm text-gray-600 ml-2">
                          Create a product
                        </div>
                      </button>
                    </div>
                  </Popover.Panel>
                </Popover>
              </>
            }
          </div>
          {category?.availableBetween != null &&
            <CategoryAvailabilityLabel
              availableBetween={category?.availableBetween}
              isFullSize
            />
          }
        </div>
        <div className="flex-1 flex flex-col overflow-y-auto">
          {(isPending || requestError != null) ? (
            <div className="flex-1 flex flex-col items-center justify-center">
              {isPending &&
                <Loader
                  type="Bars"
                  color="#3B82F6"
                  width={24}
                  height={24}
                />
              }
              {requestError != null &&
                <p>Error: {requestError.message}</p>
              }
            </div>
          ) : (
            <>
              {category != null ? (
                <>
                  {subcategoryItems}
                  {productItems}
                </>
              ) : isSearching ? (
                <>
                  {productItems}
                </>
              ) : (
                <>
                  {rootCategoryItems}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  )
}
