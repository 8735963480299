import { useAsync } from "react-async"

import { GDAPI, ScheduledOpeningHoursChange } from "../../../../lib/GDAPI"
import { ScheduledChangesListItem } from "./ScheduledChangesListItem"

export type ScheduledChangesListProps = {
  scheduledChanges: ScheduledOpeningHoursChange[]
  onScheduledChangeDeleted: () => void
}

export const ScheduledChangesList: React.FC<ScheduledChangesListProps> = ({ scheduledChanges, onScheduledChangeDeleted }) => {
  const deleteScheduledChangeRequest = useAsync({
    deferFn: deleteScheduledChange,
    onResolve: onScheduledChangeDeleted
  })

  return (
    <table className="table-auto">
      <thead className="text-left border-b">
        <tr>
          <th className="py-2 font-medium">Date</th>
          <th className="py-2 font-medium">Opening hours</th>
          <th className="py-2 font-medium"></th>
        </tr>
      </thead>
      <tbody>
        {scheduledChanges.length === 0 ? (
          <tr className="border-b">
            <td className="py-2 opacity-50" colSpan={3}>There are currently no scheduled changes.</td>
          </tr>
        ) : (
          scheduledChanges.map((change, index) => (
            <ScheduledChangesListItem
              key={index}
              scheduledChange={change}
              onDelete={change => deleteScheduledChangeRequest.run(change.date)}
              disabled={deleteScheduledChangeRequest.isPending}
            />
          ))
        )}
      </tbody>
    </table>
  )
}

async function deleteScheduledChange(args: any[], props: any, controller: AbortController): Promise<void> {
  return GDAPI.deleteScheduledOpeningHoursChange(args[0], controller)
}
