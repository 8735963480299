type Props = {
  outOfStockAt: string
  className?: string
}

export function ProductOutOfStockLabel({ outOfStockAt, className }: Props) {
  return (
    <div className="flex flex-row items-center">
      <div className={`px-2 py-0.5 self-start rounded-lg bg-gray-300 flex flex-row items-center ${className}`}>
        <div className="text-base text-gray-600 font-semibold">
          Out of stock
        </div>
      </div>
    </div>
  )
}
