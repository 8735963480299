import { Switch } from "@headlessui/react"
import * as DateFns from "date-fns"

type Props = {
  outOfStockAt?: Date
  onOutOfStockAtChanged: (outOfStockAt?: Date) => any
  disabled?: boolean
}

export function ProductOutOfStockField({ outOfStockAt, onOutOfStockAtChanged, disabled }: Props) {
  const isEnabled = outOfStockAt != null

  return (
    <div className="border-t border-b border-blue-100 py-4 flex flex-col">
      <Switch.Group>
        <div className="flex items-center">
          <Switch.Label
            className="flex-1 cursor-pointer pr-4"
          >
            <div className="text-base font-medium text-blue-500">
              Out of stock
            </div>
            <div className="text-sm font-regular text-gray-500 leading-4 mt-1">
              {outOfStockAt != null
                ? <>This item has been out of stock since <span className="font-medium text-gray-600">{DateFns.format(outOfStockAt, "EEEE do MMMM")}</span>.</>
                : "This item is currently in stock."
              }
            </div>
          </Switch.Label>
          <Switch
            as="div"
            checked={isEnabled}
            onChange={(enabled) => {
              if (disabled) {
                return
              }

              if (outOfStockAt != null) {
                onOutOfStockAtChanged(undefined)
              } else {
                onOutOfStockAtChanged(new Date())
              }
            }}
            className={`
              ${isEnabled ? "bg-blue-500" : "bg-gray-200"}
              relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
            `}
          >
            <span
              className={`
                ${isEnabled ? "translate-x-7" : "translate-x-1"}
                inline-block w-6 h-6 bg-white rounded-full
                transform transition ease-in-out duration-200 shadow
              `}
            />
          </Switch>
        </div>
      </Switch.Group>
    </div>
  )
}
