import { useState } from "react"
import { useAsync } from "react-async"
import { isEqual } from "lodash"

import { GDAPI, TimeRange } from "../../../../lib/GDAPI"
import { DailyAvailabilityField } from "../../../common/availability/DailyAvailabilityField"
import { isAfter, isSameTime } from "../../../../lib/TimeUtils"

export type CollectionsSettingsProps = {}

export const CollectionsSettings: React.FC<CollectionsSettingsProps> = () => {
  const [collectionTimes, setCollectionTimes] = useState<TimeRange[]>()

  const getCollectionTimesRequest = useAsync({
    promiseFn: fetchCollectionTimes,
    onResolve: setCollectionTimes,
  })

  const updateCollectionTimesRequest = useAsync({
    deferFn: updateCollectionTimes,
    onResolve: getCollectionTimesRequest.reload
  })

  const areCollectionTimesValid = (
    collectionTimes != null &&
    collectionTimes.every((timeRange) => isSameTime(timeRange.start, timeRange.end) || isAfter(timeRange.end, timeRange.start))
  )

  const isSaveButtonDisabled = (
    !areCollectionTimesValid ||
    getCollectionTimesRequest.isPending ||
    updateCollectionTimesRequest.isPending ||
    isEqual(collectionTimes, getCollectionTimesRequest.data)
  )

  return (
    <div className="flex flex-col">
      <div className="text-lg font-medium">Daily collection times</div>
      <p className="opacity-70 mb-4">Control when collections are available during each day of the week.</p>

      {collectionTimes != null ? (
        <>
          <DailyAvailabilityField
            availability={collectionTimes}
            onAvailabilityChanged={setCollectionTimes}
          />

          <button
            className={`my-8 sm:w-1/2 sm:self-center px-4 py-2 rounded-lg font-medium text-lg ${isSaveButtonDisabled ? `bg-gray-200 text-gray-500 opacity-75` : `bg-green-500 text-white`}`}
            onClick={() => updateCollectionTimesRequest.run(collectionTimes)}
            disabled={isSaveButtonDisabled}
          >
            {updateCollectionTimesRequest.isPending ? "Saving..." : "Save changes"}
          </button>
        </>
      ) : (
        <div className="text-center text-gray-500">Loading...</div>
      )}
    </div>
  )
}

async function fetchCollectionTimes(props: any, controller: AbortController): Promise<TimeRange[]> {
  return GDAPI.getCollectionTimes(controller)
}

async function updateCollectionTimes(args: any[], props: any, controller: AbortController): Promise<void> {
  return GDAPI.updateCollectionTimes(args[0], controller)
}
