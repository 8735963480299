import { useState } from "react"
import { useAsync } from "react-async"
import { isEqual } from "lodash"

import { GDAPI, ScheduledOpeningHoursChange, TimeRange } from "../../../../lib/GDAPI"
import { DailyAvailabilityField } from "../../../common/availability/DailyAvailabilityField"
import { ScheduleAChangeButton } from "./ScheduleAChangeButton"
import { ScheduledChangesList } from "./ScheduledChangesList"
import { SaveOpeningHoursChangesButton } from "./SaveOpeningHoursChangesButton"
import { ScheduleOpeningHoursChangeModal } from "./modals/ScheduleOpeningHoursChangeModal"
import { isAfter, isSameTime } from "../../../../lib/TimeUtils"

export type OpeningHoursSettingsProps = {}

export const OpeningHoursSettings: React.FC<OpeningHoursSettingsProps> = () => {
  const [isScheduleChangeModalOpen, setScheduleChangeModalOpen] = useState(false)
  const [openingHours, setOpeningHours] = useState<TimeRange[]>()

  const getScheduledChangesRequest = useAsync({
    promiseFn: fetchScheduledChanges
  })

  const getOpeningHoursRequest = useAsync({
    promiseFn: fetchOpeningHours,
    onResolve: setOpeningHours,
  })

  const updateOpeningHoursRequest = useAsync({
    deferFn: updateOpeningHours,
    onResolve: getOpeningHoursRequest.reload
  })

  const areOpeningHoursValid = (
    openingHours != null &&
    openingHours.every((timeRange) => isSameTime(timeRange.start, timeRange.end) || isAfter(timeRange.end, timeRange.start))
  )

  return (
    <div className="flex flex-col">
      <div className="text-lg font-medium">Scheduled changes</div>
      <p className="opacity-70 mb-4">Temporarily change the opening hours on a specific date.</p>

      {getScheduledChangesRequest.data != null ? (
        <>
          <ScheduledChangesList
            scheduledChanges={getScheduledChangesRequest.data}
            onScheduledChangeDeleted={getScheduledChangesRequest.reload}
          />

          <ScheduleAChangeButton onClick={() => setScheduleChangeModalOpen(true)} />
        </>
      ) : (
        <div className="text-center text-gray-500">Loading...</div>
      )}

      {isScheduleChangeModalOpen && (
        <ScheduleOpeningHoursChangeModal
          onClose={() => setScheduleChangeModalOpen(false)}
          onChangeScheduled={() => {
            getScheduledChangesRequest.reload()
            setScheduleChangeModalOpen(false)
          }}
        />
      )}

      <div className="text-lg font-medium mt-16">Daily opening hours</div>
      <p className="opacity-70 mb-6">Set the default opening hours for each day of the week.</p>

      {openingHours != null ? (
        <>
          <DailyAvailabilityField
            availability={openingHours}
            onAvailabilityChanged={setOpeningHours}
            disabled={updateOpeningHoursRequest.isPending}
          />

          <SaveOpeningHoursChangesButton
            onClick={() => updateOpeningHoursRequest.run(openingHours)}
            disabled={!areOpeningHoursValid || isEqual(openingHours, getOpeningHoursRequest.data)}
            saving={updateOpeningHoursRequest.isPending}
          />
        </>
      ) : (
        <div className="text-center text-gray-500">Loading...</div>
      )}
    </div>
  )
}

async function fetchScheduledChanges(props: any, controller: AbortController): Promise<ScheduledOpeningHoursChange[]> {
  return GDAPI.getScheduledOpeningHoursChanges(controller)
}

async function fetchOpeningHours(props: any, controller: AbortController): Promise<TimeRange[]> {
  return GDAPI.getOpeningHours(controller)
}

async function updateOpeningHours(args: any[], props: any, controller: AbortController): Promise<void> {
  return GDAPI.updateOpeningHours(args[0], controller)
}
