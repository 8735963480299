import { format } from "date-fns"
import { OrderDetails } from "../../../lib/GDAPI"

type FailedTransferReversalDetailsProps = {
  order: OrderDetails
}

export const FailedTransferReversalDetails: React.FC<
  FailedTransferReversalDetailsProps
> = ({ order }) => {
  const refundsWithFailedReversals = order.refunds?.filter(
    (refund) => refund.unableToReverseTransfer
  )

  if (
    refundsWithFailedReversals == null ||
    refundsWithFailedReversals.length === 0
  ) {
    return null
  }

  return (
    <div className="flex flex-col gap-y-4 px-2 my-6">
      {refundsWithFailedReversals.map((refund, index) => (
        <div key={index} className="flex flex-col gap-y-1">
          <div className="font-medium">
            Failed transfer reversal -{" "}
            {format(new Date(refund.createdAt), "d/M/yy h:mm a")}
          </div>

          <div className="flex flex-row items-center gap-x-4">
            <div className="flex-1 text-sm">
              Iona House Off Licence did not have sufficient funds in their
              Stripe balance to cover their portion of a refund.
            </div>

            <div className="text-base leading-none">
              £{(refund.amount / 100).toFixed(2)}
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
