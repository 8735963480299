import React, { useCallback } from "react"
import { Dialog } from "@headlessui/react"

import { GDAPI } from "../../../../lib/GDAPI"
import { useAsync } from "react-async"

type Props = {
  visible?: boolean
  staffMember: string
  onClose: () => void
  onStaffMemberDeleted: () => void
  existingStaffMembers: string[]
}

export const ConfirmDeleteShopStaffModal: React.FC<Props> = ({ visible, onClose, staffMember, onStaffMemberDeleted, existingStaffMembers }) => {
  const { run, isPending } = useAsync({
    deferFn: updateShopStaff,
    onResolve: () => {
      onStaffMemberDeleted()
    }
  })

  const onDialogClosed = useCallback(() => {
    if (isPending) {
      return
    }

    onClose()
  }, [isPending, onClose])

  return (
    <Dialog
      open={visible}
      onClose={onDialogClosed}
      className={styles.root}
    >
      <div className={styles.container}>
        <Dialog.Overlay className={styles.overlay} />

        <div className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Dialog.Title className={styles.title}>Delete staff member</Dialog.Title>

              <button
                className={styles.cancelButton}
                onClick={onClose}
                disabled={isPending}
              >
                Cancel
              </button>
            </div>
            
            <p>Are you sure you want to delete <span className="font-medium">{staffMember}</span>? This cannot be undone.</p>

            <button
              className={styles.confirmButton}
              onClick={() => {
                run(existingStaffMembers.filter(existingStaffMember => existingStaffMember !== staffMember))
              }}
              disabled={isPending}
            >
              Delete {staffMember}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const styles = {
  root: "fixed z-10 inset-0",
  container: "flex flex-col items-center justify-end md:justify-center h-full",
  overlay: "fixed inset-0 bg-black opacity-50",
  modal: "w-full max-w-sm flex flex-col overflow-hidden z-20 bg-white md:shadow-lg rounded-t-lg md:rounded-md lg:rounded-lg p-4 md:p-6",
  header: "flex flex-row justify-between",
  title: "font-medium",
  cancelButton: "text-red-600 hover:underline",
  content: "flex flex-col gap-y-4 md:gap-y-6",
  confirmButton: "w-full py-1.5 rounded-md bg-red-600 hover:bg-red-500 text-white",
}

async function updateShopStaff(args: any[], props: any, controller: AbortController) {
  const [shopStaff] = args as [string[]]
  return GDAPI.updateShopStaff(shopStaff, controller)
}
