import { Switch, Transition } from "@headlessui/react"
import { forwardRef, useEffect, useRef, useState } from "react"
import { StarIcon } from "@heroicons/react/solid"
import _ from "lodash"

import { ProductOfferSummary } from "../../../../../lib/GDAPI"
import { usePrevious } from "../../../../../hooks/UsePrevious"

type InputProps = {
  value: string
  onValueChanged: (value: string) => any
  disabled?: boolean
  onSubmit?: () => any
  className?: string
}

const ProductOfferNumberInput = forwardRef<HTMLInputElement, InputProps>(({
  value, onValueChanged, disabled, onSubmit, className
}, ref) => {
  const parsedValue = parseInt(value)
  const hasError = isNaN(parsedValue) || parsedValue < 1

  return (
    <input
      type="number"
      className={`
        ${className}
        w-16 text-center
        appearance-none border rounded py-2 text-base
        text-black
        shadow
        ${disabled 
          ? "border-gray-500 opacity-50" 
          : hasError
            ? "border-red-500"
            : "border-blue-500"
        }
        ${hasError ? "bg-red-100" : "bg-white"}
      `}
      value={value}
      onChange={event => {
        onValueChanged(event.target.value)
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          e.preventDefault()
          
          if (onSubmit) {
            onSubmit()
          }
        }
      }}
      disabled={disabled}
      required
      min={1}
      ref={ref}
    />
  )
})

type Props = {
  offer?: Partial<ProductOfferSummary>
  onOfferChanged: (offer?: Partial<ProductOfferSummary>) => any
  disabled?: boolean
}

export function ProductOfferField({ offer, onOfferChanged, disabled }: Props) {
  const quantityInput = useRef<HTMLInputElement>(null)
  const priceInput = useRef<HTMLInputElement>(null)

  const [quantityValue, setQuantityValue] = useState("")
  const [priceValue, setPriceValue] = useState("")

  const [isFeatured, setFeatured] = useState(false)

  const previousOffer = usePrevious(offer)
  const isEnabled = offer != null

  useEffect(() => {
    if (offer == null || _.isEqual(offer, previousOffer)) {
      return
    }

    setQuantityValue(offer.quantity?.toString() ?? "")
    setPriceValue(offer.price?.toString() ?? "")
    setFeatured(offer.isFeatured ?? false)
  }, [offer, previousOffer])

  useEffect(() => {
    const quantity = parseInt(quantityValue)
    const price = parseInt(priceValue)

    if (isNaN(quantity) || isNaN(price)) {
      return
    }

    onOfferChanged({
      quantity,
      price,
      isFeatured
    })
  }, [
    quantityValue, priceValue, isFeatured, onOfferChanged
  ])

  return (
    <div className="border-t border-b border-blue-100 py-4 flex flex-col">
      <Switch.Group>
        <div className="flex items-center">
          <Switch.Label
            className="flex-1 cursor-pointer pr-4"
          >
            <div className="text-base font-medium text-blue-500">
              Special offer
            </div>
            <div className="text-sm font-regular text-gray-500 leading-4 mt-1">
              Add a discount or multi-buy offer.
            </div>
          </Switch.Label>
          <Switch
            as="div"
            checked={isEnabled}
            onChange={(enabled) => {
              if (disabled) {
                return
              }

              if (offer != null) {
                onOfferChanged(undefined)
              } else {
                const quantity = parseInt(quantityValue)
                const price = parseInt(priceValue)

                onOfferChanged({
                  quantity: isNaN(quantity) ? 1 : quantity,
                  price: isNaN(price) ? 99 : price,
                  isFeatured
                })
              }
            }}
            className={`
              ${isEnabled ? "bg-blue-500" : "bg-gray-200"}
              relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
            `}
          >
            <span
              className={`
                ${isEnabled ? "translate-x-7" : "translate-x-1"}
                inline-block w-6 h-6 bg-white rounded-full
                transform transition ease-in-out duration-200 shadow
              `}
            />
          </Switch>
        </div>
      </Switch.Group>
      <Transition
        show={isEnabled}
        enter="transition-opacity ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-col">
          <div className="flex flex-row my-4">
            <div className="flex-1 flex flex-col">
              <label
                htmlFor="from"
                className="text-base font-medium text-blue-500 mb-1"
              >
                Quantity
              </label>
              <div className="flex flex-row items-center">
                <ProductOfferNumberInput
                  value={quantityValue}
                  onValueChanged={setQuantityValue}
                  disabled={disabled}
                  ref={quantityInput}
                  onSubmit={() => {
                    priceInput.current?.focus()
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col">
              <label
                htmlFor="from"
                className="text-base font-medium text-blue-500 mb-1"
              >
                Price (Pence)
              </label>
              <div className="flex flex-row items-center justify-end">
                <ProductOfferNumberInput
                  value={priceValue}
                  onValueChanged={setPriceValue}
                  disabled={disabled}
                  ref={priceInput}
                  onSubmit={() => {
                    priceInput.current?.blur()
                  }}
                />
              </div>
            </div>
          </div>
          <Switch.Group>
            <div className="flex items-center">
              <Switch.Label
                className="flex-1 cursor-pointer pr-4"
              >
                <div className="text-base font-medium text-blue-500">
                  Featured offer
                </div>
                <div className="text-sm font-regular text-gray-500 leading-4 mt-1">
                  Products with <span className="font-medium">featured <StarIcon className="inline-flex w-5 h-5 text-yellow-400" /> special offers</span> are shown on the <span className="font-medium">Latest</span> tab in the mobile app.
                </div>
              </Switch.Label>
              <Switch
                as="div"
                checked={isFeatured}
                onChange={(enabled) => {
                  if (disabled) {
                    return
                  }

                  setFeatured(enabled)
                }}
                className={`
                  ${isFeatured ? "bg-blue-500" : "bg-gray-200"}
                  relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
                `}
              >
                <span
                  className={`
                    ${isFeatured ? "translate-x-7" : "translate-x-1"}
                    inline-block w-6 h-6 bg-white rounded-full
                    transform transition ease-in-out duration-200 shadow
                  `}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
      </Transition>
    </div>
  )
}
