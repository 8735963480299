import { useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { UploadIcon, XIcon, MinusCircleIcon, StarIcon } from "@heroicons/react/solid"
import { ExclamationIcon, ExclamationCircleIcon, InformationCircleIcon } from "@heroicons/react/outline"
import Loader from "react-loader-spinner"

import { TextField } from "../../../../common/TextField"
import { useAsync } from "react-async"
import { GDAPI, ProductCategorySummary, TimeRange } from "../../../../../lib/GDAPI"
import { CategoryAvailabilityField } from "./CategoryAvailabilityField"
import { CategoryPicker } from "./CategoryPicker"
import { CategoryHiddenField } from "./CategoryHiddenField"
import { CategoryExcludedFromDiscountField } from "./CategoryExcludedFromDiscountField"
import { CategoryAgeVerificationRequiredField } from "./CategoryAgeVerificationRequiredField"
import { CategoryFeaturedField } from "./CategoryFeaturedField"
import { MerchantPicker } from "./MerchantPicker"

type CategoryDialogProps = {
  isOpen: boolean
  onClose: () => any
  onCategoryCreated: (category: ProductCategorySummary) => any
  existingCategory?: ProductCategorySummary
  onCategoryUpdated: (category: ProductCategorySummary) => any
  onCategoryDeleted: () => any
  currentCategoryId?: number
  currentMerchantId?: number
}

async function performCategoryAction(args: any[], props: any, controller: AbortController) {
  const [data, categoryId] = args as [Partial<ProductCategorySummary> | undefined, number | undefined]

  if (data != null && categoryId != null) {
    props.onCategoryUpdated(await GDAPI.updateProductCategory([data, categoryId], props, controller))
  } else if (categoryId != null) {
    props.onCategoryDeleted(await GDAPI.deleteProductCategory([categoryId], props, controller))
  } else {
    props.onCategoryCreated(await GDAPI.createProductCategory([data], props, controller))
  }
}

async function uploadImage(args: any[], props: any, controller: AbortController) {
  const url = await GDAPI.uploadImage(args, props, controller)
  props.onImageUploaded(url)
}

export function CategoryDialog({ isOpen, onClose, onCategoryCreated, existingCategory, onCategoryUpdated, onCategoryDeleted, currentCategoryId, currentMerchantId }: CategoryDialogProps) {
  const [nameValue, setNameValue] = useState("")
  const nameInput = useRef<HTMLInputElement>(null)
  const focusRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const submitRef = useRef<HTMLInputElement>(null)
  const [formError, setFormError] = useState<string | undefined>()
  const [isDeleting, setIsDeleting] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadedImageURL, setUploadedImageURL] = useState<string>()
  const [removeExistingImage, setRemoveExistingImage] = useState(false)
  const [categoryAvailability, setCategoryAvailability] = useState<TimeRange[] | undefined>()
  const [selectedParentCategoryId, setSelectedParentCategoryId] = useState<number | null>(null)
  const [isHidden, setHidden] = useState(false)
  const [excludedFromDiscount, setExcludedFromDiscount] = useState(false)
  const [ageVerificationRequired, setAgeVerificationRequired] = useState(false)
  const [isFeatured, setFeatured] = useState(false)
  const [selectedMerchantId, setSelectedMerchantId] = useState<number | null>(null)

  useEffect(() => {
    if (!isOpen) {
      setNameValue("") 
      setIsDeleting(false)
      setUploadedImageURL(undefined)
      setRemoveExistingImage(false)
      setCategoryAvailability(undefined)
      setSelectedParentCategoryId(null)
      setHidden(false)
      setExcludedFromDiscount(false)
      setAgeVerificationRequired(false)
      setFeatured(false)
      setSelectedMerchantId(null)
    } else {
      setSelectedParentCategoryId(currentCategoryId ?? null)
      setSelectedMerchantId(currentMerchantId ?? null)
    }
  }, [
    isOpen,
    setNameValue,
    setIsDeleting,
    setUploadedImageURL,
    setRemoveExistingImage,
    setCategoryAvailability,
    setSelectedParentCategoryId,
    setHidden,
    setExcludedFromDiscount,
    setAgeVerificationRequired,
    setFeatured,
    setSelectedMerchantId,
    currentCategoryId,
    currentMerchantId
  ])

  useEffect(() => {
    if (existingCategory == null) {
      return
    }

    setNameValue(existingCategory.name)
    setCategoryAvailability(existingCategory.availableBetween)
    setSelectedParentCategoryId(existingCategory.parentCategory)
    setHidden(existingCategory.isHidden)
    setExcludedFromDiscount(existingCategory.excludedFromDiscount)
    setAgeVerificationRequired(existingCategory.ageVerificationRequired)
    setFeatured(existingCategory.isFeatured)
    setSelectedMerchantId(existingCategory.merchant)
  }, [existingCategory, setNameValue, setCategoryAvailability, setSelectedParentCategoryId, setHidden, setExcludedFromDiscount, setAgeVerificationRequired, setFeatured, setSelectedMerchantId])

  const { error, isPending, run } = useAsync({
    deferFn: performCategoryAction,
    onCategoryCreated,
    onCategoryUpdated,
    onCategoryDeleted
  })
  
  const imageUploader = useAsync({
    deferFn: uploadImage,
    onImageUploaded: setUploadedImageURL
  })

  const isPerformingOperation = isPending || imageUploader.isPending
  const isSpecialCategory = existingCategory != null && existingCategory.id >= 9_000_000_000

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        if (!isPerformingOperation) {
          onClose()
        }
      }}
      className="fixed z-10 inset-0 h-full"
      initialFocus={focusRef}
    >
      <div className="flex flex-row justify-center h-full">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div
          ref={focusRef}
          className={`
            z-20
            bg-white
            max-w-sm w-full
            md:rounded lg:rounded-lg
            shadow-lg

            flex flex-col
            sm:my-8
            overflow-hidden
          `}
        >
          <div
            className={`
              flex flex-row
              border-b border-gray-200
            `}
          >
            <Dialog.Title className="text-xl font-medium flex-1 p-4">
              {existingCategory != null
                ? isDeleting
                  ? "Delete category"
                  : "Update category"
                : "Create a category"
              }
            </Dialog.Title>
            <div className="flex flex-col">
              <button 
                className={`
                  flex-1 px-4
                  flex flex-row items-center justify-center

                  bg-gray-200
                `}
                disabled={isPerformingOperation}
                onClick={onClose}
              >
                <XIcon className="h-6 w-6 text-gray-800" />
              </button>
            </div>
          </div>
          <div className="shadow-inner flex-1 flex flex-col items-start overflow-y-auto">
            <div className="w-full px-4 pt-4 pb-16">
              {existingCategory != null && isDeleting ? (
                <>
                  <div className="text-lg font-regular text-center">
                    Are you sure you want to delete this category?
                  </div>
                  <div
                    className="border-2 border-red-500 border-dashed rounded bg-white w-full text-left p-4 flex flex-row items-center my-4"
                  >
                    <div className="w-16 h-16 rounded-lg">
                      {existingCategory.image != null ? (
                        <img
                          src={existingCategory.image}
                          className="w-16 h-16 object-contain rounded-lg"
                          alt={existingCategory.name}
                        />
                      ) : (
                        <div className="w-full h-full rounded-md border-2 border-dashed border-blue-200 flex justify-center items-center">
                          <div className="hidden text-xs text-blue-200 font-medium text-center">
                            Category Image
                          </div>
                        </div>
                      )}
                    </div>        
                    <div className="flex-1 flex flex-col ml-3 mr-4">
                      <div className="text-base font-medium text-gray-800 leading-4 break-all">
                        {existingCategory.name}
                      </div>
                      {existingCategory.products > 0 &&
                        <div className="text-sm font-normal text-gray-600 leading-4 mt-1">
                          {existingCategory.products === 1 ? "1 product " : `${existingCategory.products} products `}
                          {existingCategory.offers === 1 ? "- 1 offer" : existingCategory.offers > 1 ? `- ${existingCategory.offers} offers` : ""}
                        </div>
                      }
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center p-4 bg-red-50 border border-red-500 rounded-lg text-red-500">
                    <ExclamationIcon className="w-6 h-6 text-red-500 mr-3" />
                    <div className="flex-1 text-sm font-regular">
                      All products in this category will be deleted. <span className="font-medium">This action cannot be undone.</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {isSpecialCategory ? (
                    <>
                      <div className="text-lg font-regular text-center">
                        This category is automatically generated and cannot be edited.
                      </div>
                      <div
                        className="border border-blue-200 rounded bg-white w-full text-left p-4 flex flex-row items-center my-4"
                      >
                        <div className="w-16 h-16 rounded-lg">
                          {existingCategory!.image != null ? (
                            <img
                              src={existingCategory!.image}
                              className="w-16 h-16 object-contain rounded-lg"
                              alt={existingCategory!.name}
                            />
                          ) : (
                            <div className="w-full h-full rounded-md border-2 border-dashed border-blue-200 flex justify-center items-center">
                              <div className="hidden text-xs text-blue-200 font-medium text-center">
                                Category Image
                              </div>
                            </div>
                          )}
                        </div>        
                        <div className="flex-1 flex flex-col ml-3 mr-4">
                          <div className="text-base font-medium text-gray-800 leading-4 break-all">
                            {existingCategory!.name}
                          </div>
                          {existingCategory!.offers > 0 &&
                            <div className="text-sm font-normal text-gray-600 leading-4 mt-1">
                              {existingCategory!.offers === 1 ? "1 offer" : `${existingCategory!.offers} offers`}
                            </div>
                          }
                        </div>
                      </div>
                      {existingCategory!.id === 9_000_000_000 &&
                        <>
                          <div className="flex flex-row items-center justify-center p-4 bg-blue-50 border border-blue-200 rounded-lg text-blue-600 mb-4">
                            <InformationCircleIcon className="w-6 h-6 text-blue-600 mr-3" />
                            <div className="flex-1 text-sm font-regular">
                              Products with <span className="font-medium">featured <StarIcon className="inline-flex w-5 h-5 text-yellow-400" /> special offers</span> will automatically appear in this category.
                            </div>
                          </div>

                          <div className="flex flex-row items-center justify-center p-4 bg-yellow-50 border border-yellow-500 rounded-lg text-yellow-600">
                            <ExclamationCircleIcon className="w-6 h-6 text-yellow-600 mr-3" />
                            <div className="flex-1 text-sm font-regular">
                              This category is not part of the menu. Featured offers are shown separately on the <span className="font-medium">Latest</span> tab in the mobile app.
                            </div>
                          </div>
                        </>
                      }
                      {existingCategory!.id === 9_000_000_001 &&
                        <>
                          <div className="flex flex-row items-center justify-center p-4 bg-blue-50 border border-blue-200 rounded-lg text-blue-600 mb-4">
                            <InformationCircleIcon className="w-6 h-6 text-blue-600 mr-3" />
                            <div className="flex-1 text-sm font-regular">
                              Products with <span className="font-medium">special offers</span> will automatically appear in this category.
                            </div>
                          </div>
                        </>
                      }
                    </>
                  ) : (
                    <>
                      {existingCategory != null &&
                        <button
                          onClick={() => setIsDeleting(true)}
                          className={`
                            w-full
                            flex flex-row items-center justify-center
                            py-2 px-4
                            bg-red-100 text-red-800
                            rounded-lg shadow-sm
                            border border-transparent
                            mt-2 mb-8
                          `}
                        >
                          <MinusCircleIcon className="w-5 h-5 mr-1" />
                          <div className="text-base font-medium">
                            Delete category
                          </div>
                        </button>
                      }
                      <div className="flex flex-row justify-center mt-4 mb-8">
                        <div className="w-24 h-24 rounded-lg mr-8 flex items-center justify-center">
                          {uploadedImageURL != null || (existingCategory?.image != null && !removeExistingImage) ? (
                            <img
                              src={uploadedImageURL ?? existingCategory?.image!}
                              className="w-full h-full object-contain rounded-lg"
                              alt={existingCategory?.name}
                            />
                          ) : (
                            imageUploader.isPending ? (
                              <div className="w-full h-full flex items-center justify-center">
                                <Loader
                                  type="Oval"
                                  color="#10B981"
                                  width={48}
                                  height={48}
                                />
                              </div>
                            ) : (
                              <div className="w-full h-full rounded-lg border-4 border-blue-200 border-dashed flex items-center justify-center">
                                <div className="text-base font-medium text-blue-200 text-center">
                                  Category Image
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <div className="flex flex-col justify-center">
                          <input
                            type="file"
                            name="image"
                            accept="image/jpeg,image/png,image/webp,image/svg+xml"
                            className="hidden"
                            ref={fileInputRef}
                            onChange={e => {
                              if (e.target.files === null || e.target.files.length === 0) {
                                return
                              }

                              setUploadedImageURL(undefined)

                              const file = e.target.files[0]
                              imageUploader.run(file)
                            }}
                          />
                          <button
                            onClick={() => fileInputRef.current?.click()}
                            className={`
                              flex flex-row items-center justify-center
                              py-2 px-4
                              bg-blue-100 text-blue-800
                              rounded-lg shadow-sm
                              border border-transparent
                            `}
                          >
                            <UploadIcon className="w-5 h-5 mr-1" />
                            <div className="text-sm font-medium">
                              Upload image
                            </div>
                          </button>
                          {(existingCategory?.image != null || uploadedImageURL != null) &&
                            <button
                              onClick={() => {
                                if (uploadedImageURL != null) {
                                  setUploadedImageURL(undefined)
                                } else {
                                  setRemoveExistingImage(true)
                                }
                              }}
                              className={`
                                flex flex-row items-center justify-center
                                py-2 px-4
                                bg-red-100 text-red-800
                                rounded-lg shadow-sm
                                border border-transparent
                                mt-4
                              `}
                            >
                              <XIcon className="w-5 h-5 mr-1" />
                              <div className="text-sm font-medium">
                                Remove image
                              </div>
                            </button>
                          }
                        </div>
                      </div>
        
                      <form
                        ref={formRef}
                        autoComplete="off"
                        onSubmit={event => {
                          event.preventDefault()

                          if (isPerformingOperation) {
                            return
                          }

                          nameInput.current?.blur()
                          setFormError(undefined)    
        
                          const name = nameValue.trim()
        
                          if (name.length === 0) {
                            setFormError("Please provide a name.")
                            return
                          }

                          const categoryData = {
                            name,
                            image: removeExistingImage && uploadedImageURL == null
                              ? null
                              : uploadedImageURL,
                            availableBetween: categoryAvailability ?? null,
                            parentCategoryId: selectedParentCategoryId,
                            isHidden,
                            excludedFromDiscount,
                            ageVerificationRequired,
                            isFeatured,
                            merchantId: selectedMerchantId
                          }
        
                          run(categoryData, existingCategory?.id)
                        }}
                      >
                        <MerchantPicker
                          label="Sold by merchant"
                          selectedMerchantId={selectedMerchantId}
                          onMerchantSelected={setSelectedMerchantId}
                          className="mb-4"
                        />

                        <CategoryPicker
                          label="Parent category"
                          selectedCategoryId={selectedParentCategoryId}
                          onCategorySelected={setSelectedParentCategoryId}
                          className="mb-4"
                          hiddenCategories={
                            existingCategory != null
                              ? [existingCategory.id]
                              : undefined
                          }
                        />

                        <TextField
                          name="Name"
                          value={nameValue}
                          onValueChanged={setNameValue}
                          required
                          disabled={isPerformingOperation}
                          ref={nameInput}
                          className="mb-4"
                          onSubmit={() => {
                            nameInput.current?.blur()
                          }}
                        />

                        <CategoryAvailabilityField
                          disabled={isPerformingOperation}
                          categoryAvailability={categoryAvailability}
                          onCategoryAvailabilityChanged={setCategoryAvailability}
                        />

                        <CategoryExcludedFromDiscountField
                          disabled={isPerformingOperation}
                          excludedFromDiscounts={excludedFromDiscount}
                          onExcludedChanged={setExcludedFromDiscount}
                        />

                        <CategoryAgeVerificationRequiredField
                          disabled={isPerformingOperation}
                          ageVerificationRequired={ageVerificationRequired}
                          onAgeVerificationRequiredChanged={setAgeVerificationRequired}
                        />

                        <CategoryFeaturedField
                          disabled={isPerformingOperation}
                          isFeatured={isFeatured}
                          onFeaturedChanged={setFeatured}
                        />

                        <CategoryHiddenField
                          disabled={isPerformingOperation}
                          isHidden={isHidden}
                          onHiddenChanged={setHidden}
                        />
        
                        <input
                          type="submit"
                          className="hidden"
                          ref={submitRef}
                        />
                      </form>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col p-4 border-t border-gray-200">
            <Transition
              show={error != null || formError != null}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-25"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="py-2 px-4 bg-red-100 text-red-600 text-base font-medium rounded text-center mb-4">
                {error?.message ?? formError}
              </div>
            </Transition>
            <div className="flex flex-row">
              <button
                onClick={() => {
                  if (isDeleting) {
                    setIsDeleting(false)
                  } else {
                    onClose()
                  }
                }}
                disabled={isPerformingOperation}
                className={`
                  flex-1
                  py-2 px-4
                  ${isSpecialCategory ? "mr-0" : "mr-4"}
                  bg-gray-200
                  border border-transparent
                  text-gray-700 text-base font-medium
                  rounded-lg shadow
                `}
              >
                {isDeleting ? "No" : "Cancel"}
              </button>
              <button
                onClick={() => {
                  if (existingCategory != null && isDeleting) {
                    run(undefined, existingCategory.id)
                    return
                  }

                  submitRef.current?.click()
                }}
                disabled={isPerformingOperation}
                className={`
                  flex-1
                  ${isSpecialCategory ? "hidden" : "flex"} flex-row items-center justify-center
                  py-2 px-4
                  ml-4
                  
                  ${isPending
                    ? isDeleting
                      ? "bg-red-700"
                      : existingCategory != null
                        ? "bg-yellow-500"
                        : "bg-green-500"
                    : isDeleting
                      ? "bg-red-500"
                      : existingCategory != null
                        ? "bg-yellow-200"
                        : "bg-green-200"
                  }

                  border border-transparent
                  rounded-lg shadow
                `}
              >
                {isPending &&
                  <div className="mr-2">
                    <Loader
                      type="Oval"
                      color="#FFFFFF"
                      width={20}
                      height={20}
                    />
                  </div>
                }
                <div
                  className={`
                    text-base font-medium
                    ${isPending || isDeleting ? "text-white" : existingCategory != null ? "text-yellow-800" : "text-green-800"}
                  `}
                >
                  {isPending
                    ? isDeleting
                      ? "Deleting..."
                      : existingCategory != null
                        ? "Updating..."
                        : "Creating..."
                    : isDeleting
                      ? "Yes, delete"
                      : existingCategory != null
                        ? "Update"
                        : "Create"
                  }
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}