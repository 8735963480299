import React, { useCallback, useState } from "react"
import { Dialog } from "@headlessui/react"
import classNames from "clsx"

import { TextField } from "../../../common/TextField"
import { CustomerSearchResult, GDAPI } from "../../../../lib/GDAPI"
import { useAsync } from "react-async"
import { capitalize } from "lodash"

type Props = {
  visible?: boolean
  onClose: () => void
  onStaffMemberAdded: () => void
}

export const AddDeliveryStaffModal: React.FC<Props> = ({ visible, onClose, onStaffMemberAdded }) => {
  const [staffMemberName, setStaffMemberName] = useState("")
  const [errorMessage, setErrorMessage] = useState<string>()

  const { run: runSearchCustomers, isPending: isCustomerSearchPending, data: matchingCustomers } = useAsync({
    deferFn: searchCustomers,
  })

  const { run: runAddDeliveryStaff, isPending: isNewAdditionPending } = useAsync({
    deferFn: addDeliveryStaff,
    onResolve: () => {
      onStaffMemberAdded()
    }
  })

  const isPending = isCustomerSearchPending || isNewAdditionPending

  const onDialogClosed = useCallback(() => {
    if (isPending) {
      return
    }

    onClose()
  }, [isPending, onClose])

  const onSearchClicked = useCallback(() => {
    const query = capitalize(staffMemberName.trim())

    if (query.length === 0) {
      setErrorMessage("Please provide a name.")
      return
    }

    setErrorMessage(undefined)
    runSearchCustomers(query)
  }, [staffMemberName, setErrorMessage, runSearchCustomers])

  const [selectedCustomer, setSelectedCustomer] = useState<CustomerSearchResult>()

  const matchingCustomerResults = matchingCustomers?.map((customer) => (
    <button
      className={classNames(styles.matchingCustomer, selectedCustomer?.id === customer.id ? "bg-brand-100 hover:bg-brand-100" : "bg-white")} key={customer.id}
      onClick={() => setSelectedCustomer(customer)}
    >
      <div className={styles.matchingCustomerName}>{customer.name}</div>
      <div className={styles.matchingCustomerPhoneNumber}>{customer.phoneNumber}</div>
    </button>
  ))

  return (
    <Dialog
      open={visible}
      onClose={onDialogClosed}
      className={styles.root}
    >
      <div className={styles.container}>
        <Dialog.Overlay className={styles.overlay} />

        <div className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Dialog.Title className={styles.title}>Add delivery staff</Dialog.Title>

              <button
                className={styles.cancelButton}
                onClick={onClose}
                disabled={isPending}
              >
                Cancel
              </button>
            </div>
            

            <TextField
              className={styles.nameTextField}
              name="Name"
              value={staffMemberName}
              onValueChanged={setStaffMemberName}
              primary
              required
              disabled={isPending}
            />

            {errorMessage != null && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}

            <button
              className={styles.findButton}
              onClick={onSearchClicked}
              disabled={isPending}
            >
              Find account
            </button>

            {matchingCustomers != null && (
              <>
                <p className={styles.resultsSummary}>{matchingCustomers.length} account{matchingCustomers.length === 1 ? "" : "s"} found</p>

                {matchingCustomers.length > 0 && (
                  <>
                    <div className={styles.resultsContainer}>
                      {matchingCustomerResults}
                    </div>

                    <button
                      className={classNames(styles.addButton, selectedCustomer ? "bg-brand-600 hover:bg-brand-500" : "bg-gray-500")}
                      onClick={() => {
                        if (!selectedCustomer) {
                          return
                        }

                        runAddDeliveryStaff(selectedCustomer.id)
                      }}
                      disabled={!selectedCustomer || isPending}
                    >
                      {selectedCustomer ? `Add ${selectedCustomer.name}` : "Select an account"}
                    </button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const styles = {
  root: "fixed z-10 inset-0",
  container: "flex flex-col items-center justify-end md:justify-center h-full",
  overlay: "fixed inset-0 bg-black opacity-50",
  modal: "w-full max-w-sm flex flex-col overflow-hidden z-20 bg-white md:shadow-lg rounded-t-lg md:rounded-md lg:rounded-lg p-4 md:p-6",
  header: "flex flex-row justify-between",
  title: "font-medium",
  cancelButton: "text-red-600 hover:underline",
  content: "flex flex-col gap-y-4 md:gap-y-6",
  findButton: "w-full py-1.5 rounded-md bg-brand-600 hover:bg-brand-500 text-white",
  nameTextField: "",
  errorMessage: "text-red-600",
  resultsSummary: "font-medium",
  resultsContainer: "h-64 border border-gray-200 overflow-y-auto",
  matchingCustomer: "w-full h-16 flex flex-col justify-center border-b px-4 hover:bg-gray-100",
  matchingCustomerName: "",
  matchingCustomerPhoneNumber: "text-sm font-light",
  addButton: "w-full py-1.5 rounded-md text-white",
}

async function searchCustomers(args: any[], props: any, controller: AbortController) {
  const [query] = args as [string]
  return GDAPI.searchCustomers(query, controller)
}

async function addDeliveryStaff(args: any[], props: any, controller: AbortController) {
  const [id] = args as [number]
  return GDAPI.addDeliveryStaff(id, controller)
}
