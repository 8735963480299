import { format as formatDate } from "date-fns"

import { ScheduledOpeningHoursChange } from "../../../../lib/GDAPI"
import { formatTime, isSameTime } from "../../../../lib/TimeUtils"

export type ScheduledChangesListItemProps = {
  scheduledChange: ScheduledOpeningHoursChange
  onDelete?: (scheduledChange: ScheduledOpeningHoursChange) => void
  disabled?: boolean
}

export const ScheduledChangesListItem: React.FC<ScheduledChangesListItemProps> = ({ scheduledChange, onDelete, disabled }) => {
  const { date, openingHours: { start, end } } = scheduledChange
  const formattedDate = formatDate(new Date(date), "EEEE do MMMM yyyy")
  const openTime = formatTime(start, true)
  const closeTime = formatTime(end, true)
  const isClosed = isSameTime(start, end)

  return (
    <tr className="border-b hover:bg-gray-50">
      <td className="py-2">{formattedDate}</td>
      <td className="py-2">{isClosed ? "Closed" : `${openTime} - ${closeTime}`}</td>
      <td
        className="py-2 font-medium cursor-pointer text-red-600 hover:underline"
        onClick={disabled ? undefined : () => onDelete?.(scheduledChange)}
      >
        Delete
      </td>
    </tr>
  )
}
