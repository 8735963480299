import { FC, useState, useRef } from "react"
import { useAsync } from "react-async"
import { Transition } from "@headlessui/react"

import GettaDeliveryLogo from "../../assets/getta_delivery_logo.png"

import { TextField } from "../common/TextField"
import { GDAPI } from "../../lib/GDAPI"

export const ForgotPasswordPage: FC = () => {
  const [emailInput, setEmailInput] = useState<string>("")
  const emailFieldRef = useRef<HTMLInputElement>(null)

  const [formError, setFormError] = useState<string>()

  const { error, isPending, run, isResolved } = useAsync({ deferFn: GDAPI.requestPasswordReset })

  return (
    <div className="flex flex-col items-center bg-white shadow px-4 py-6 flex-1">
      <div className="flex flex-col max-w-sm w-full">
        <img
          className="object-contain h-24 mb-6"
          src={GettaDeliveryLogo}
          alt="Getta Delivery"
        />

        <form
          onSubmit={event => {
            event.preventDefault()
            emailFieldRef.current?.blur()
            setFormError(undefined)

            const emailAddress = emailInput.trim()

            if (emailAddress.length === 0) {
              setFormError("Please enter your email address.")
              return
            }

            run({ emailAddress })
          }}
        >
          <Transition
            show={error != null || formError != null}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-25"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="py-2 px-4 bg-red-100 text-red-600 text-base font-medium rounded text-center mb-4">
              {error?.message ?? formError}
            </div>
          </Transition>

          <Transition
            show={isResolved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-25"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="py-2 px-4 bg-green-100 text-green-600 text-base font-medium rounded text-center mb-4">
              You should receive an email containing a password reset link shortly.
            </div>
          </Transition>

          <TextField
            ref={emailFieldRef}
            type="email"
            name="Email address"
            value={emailInput}
            onValueChanged={setEmailInput}
            required
            className="mb-2"
            disabled={isPending}
            primary
          />

          <input
            type="submit"
            value={isPending ? "Please wait" : "Request password reset"}
            className={`
              w-full
              p-2
              rounded-md
              text-lg font-medium 
              shadow
              mt-6
              ${isPending
                  ? "cursor-wait bg-gray-300 text-gray-600"
                  : "cursor-pointer bg-brand-500-lighter text-brand-700 hover:bg-brand-500 hover:text-white"
              }
            `}
            disabled={isPending}
          />
        </form>
      </div>
    </div>
  )
}
