import { Switch } from "@headlessui/react"

import { BasketItem, OrderDetails } from "../../../../lib/GDAPI"
import { OrderHelper } from "../../../../lib/OrderHelper"
import { ItemRefund, OrderRefund } from "../../../../models/OrderRefund"

export type OrderRefundPickerProps = {
  order: OrderDetails
  value: OrderRefund
  onChange: (refund: OrderRefund) => void
  disabled?: boolean
}

export const OrderRefundPicker: React.FC<OrderRefundPickerProps> = ({
  order,
  value,
  onChange,
  disabled,
}) => {
  const shopItems: BasketItem[] = []
  const merchantItems: BasketItem[] = []

  for (const item of order.basketItems) {
    if (item.quantity - OrderHelper.getRefundedQuantity(item, order) <= 0) {
      continue
    }

    if (item.merchant != null) {
      merchantItems.push(item)
    } else {
      shopItems.push(item)
    }
  }

  const hasDeliveryFeeBeenRefunded = order.refunds?.some(
    (refund) => refund.includesDeliveryFee || !refund.items
  )

  return (
    <div className="flex flex-col gap-y-4">
      {shopItems.length > 0 && (
        <div className="flex flex-col">
          <div className="font-medium">Vivo Spencer Road</div>

          {shopItems.map((item) => (
            <OrderRefundPickerItem
              key={item.product.id}
              order={order}
              item={item}
              value={value.items.find(
                (itemRefund) => itemRefund.id === item.product.id
              )}
              onChange={(itemRefund) => {
                let newItems: ItemRefund[]

                if (itemRefund.quantity <= 0) {
                  newItems = value.items.filter(
                    (itemRefund) => itemRefund.id !== item.product.id
                  )
                } else {
                  const index = value.items.findIndex(
                    (itemRefund) => itemRefund.id === item.product.id
                  )

                  newItems = [...value.items]

                  if (index === -1) {
                    newItems.push(itemRefund)
                  } else {
                    newItems[index] = itemRefund
                  }
                }

                onChange({
                  ...value,
                  items: newItems,
                })
              }}
              disabled={disabled}
            />
          ))}
        </div>
      )}

      {merchantItems.length > 0 && (
        <div className="flex flex-col">
          <div className="font-medium">Iona House Off Licence</div>

          {merchantItems.map((item) => (
            <OrderRefundPickerItem
              key={item.product.id}
              order={order}
              item={item}
              value={value.items.find(
                (itemRefund) => itemRefund.id === item.product.id
              )}
              onChange={(itemRefund) => {
                let newItems: ItemRefund[]

                if (itemRefund.quantity <= 0) {
                  newItems = value.items.filter(
                    (itemRefund) => itemRefund.id !== item.product.id
                  )
                } else {
                  const index = value.items.findIndex(
                    (itemRefund) => itemRefund.id === item.product.id
                  )

                  newItems = [...value.items]

                  if (index === -1) {
                    newItems.push(itemRefund)
                  } else {
                    newItems[index] = itemRefund
                  }
                }

                onChange({
                  ...value,
                  items: newItems,
                })
              }}
              disabled={disabled}
            />
          ))}
        </div>
      )}

      {order.deliveryAddress != null && !hasDeliveryFeeBeenRefunded && (
        <div className="flex flex-col">
          <Switch.Group>
            <div className="flex flex-row items-center">
              <Switch.Label className="flex-1 cursor-pointer pr-4">
                <div className="font-medium">Delivery</div>
                <div className="">Refund delivery fee</div>
              </Switch.Label>

              <Switch
                as="div"
                checked={value.refundDeliveryFee ?? false}
                onChange={(checked: boolean) => {
                  if (disabled) {
                    return
                  }

                  onChange({
                    ...value,
                    refundDeliveryFee: checked,
                  })
                }}
                className={`${
                  value.refundDeliveryFee ? "bg-green-500" : "bg-gray-200"
                } relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer`}
              >
                <span
                  className={`${
                    value.refundDeliveryFee ? "translate-x-7" : "translate-x-1"
                  } inline-block w-6 h-6 bg-white rounded-full transform transition ease-in-out duration-200 shadow`}
                />
              </Switch>
            </div>
          </Switch.Group>
        </div>
      )}
    </div>
  )
}

type OrderRefundPickerItemProps = {
  order: OrderDetails
  item: BasketItem
  value?: ItemRefund
  onChange: (refund: ItemRefund) => void
  disabled?: boolean
}

const OrderRefundPickerItem: React.FC<OrderRefundPickerItemProps> = ({
  order,
  item,
  value,
  onChange,
  disabled,
}) => {
  const remainingQuantity =
    item.quantity - OrderHelper.getRefundedQuantity(item, order)

  return (
    <div className="flex flex-row items-center border-b py-2">
      <div className="flex-1">{item.product.name}</div>

      <div
        className="flex flex-row items-center justify-between"
        style={{ width: "120px" }}
      >
        <button
          className="bg-red-500 text-white px-2 rounded"
          onClick={() => {
            if (!value) {
              return
            }

            onChange({
              ...value,
              quantity: value.quantity - 1,
            })
          }}
          disabled={disabled || !value || value.quantity <= 0}
        >
          -
        </button>

        <div className="font-medium">
          {value?.quantity ?? 0} / {remainingQuantity}
        </div>

        <button
          className="bg-green-500 text-white px-2 rounded"
          onClick={() => {
            if (!value) {
              const itemRefund: ItemRefund = {
                id: item.product.id,
                quantity: 1,
              }

              if (item.ref) {
                itemRefund.ref = item.ref
              }

              onChange(itemRefund)
            } else {
              onChange({
                ...value,
                quantity: value.quantity + 1,
              })
            }
          }}
          disabled={
            disabled || (value != null && value.quantity >= remainingQuantity)
          }
        >
          +
        </button>
      </div>
    </div>
  )
}
