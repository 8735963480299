import { Dialog } from "@headlessui/react"
import { useState } from "react"
import { useAsync } from "react-async"

import { DayTimeRangeField } from "../../../../common/availability/DayTimeRangeField"
import { GDAPI, TimeRange } from "../../../../../lib/GDAPI"
import { addDays, format as formatDate } from "date-fns"
import { isAfter, isSameTime } from "../../../../../lib/TimeUtils"

export type ScheduleOpeningHoursChangeModalProps = {
  onClose: () => void
  onChangeScheduled: () => void,
}

export const ScheduleOpeningHoursChangeModal: React.FC<ScheduleOpeningHoursChangeModalProps> = ({ onClose, onChangeScheduled }) => {
  const [date, setDate] = useState<string>(getDefaultDate())
  const [openingHours, setOpeningHours] = useState<TimeRange>({ start: [8, 0], end: [22, 0] })
  const [errorMessage, setErrorMessage] = useState<string>()

  const scheduleChangeRequest = useAsync({
    deferFn: scheduleChange,
    onResolve: onChangeScheduled,
    onReject: (error) => setErrorMessage(error.message),
  })

  const formattedDate = formatDate(new Date(date), "EEEE do MMMM yyyy")
  const areOpeningHoursValid = isSameTime(openingHours.start, openingHours.end) || isAfter(openingHours.end, openingHours.start)

  return (
    <Dialog
      open
      onClose={() => { if (!scheduleChangeRequest.isPending) onClose()}}
      className="fixed z-10 inset-0"
    >
      <div className="flex flex-col items-center justify-center h-full">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div
          className="w-full flex flex-col overflow-hidden z-20 bg-white md:shadow-lg rounded-lg p-4 md:p-6"
          style={{ maxWidth: "500px" }}
        >
          <div className="flex flex-col gap-y-4 md:gap-y-6">
            <div className="flex flex-row justify-between">
              <Dialog.Title className="font-medium">Schedule opening hours change</Dialog.Title>

              <button
                className="text-red-500 font-medium hover:text-gray-500"
                onClick={scheduleChangeRequest.isPending ? undefined : onClose}
              >
                Cancel
              </button>
            </div>

            <div className="flex flex-col gap-y-2">
              <div className="font-medium">Date</div>

              <input
                className="border border-gray-300 rounded-md p-2"
                type="date"
                value={date}
                onChange={e => {
                  if (e.target.value.length === 0) {
                    setDate(getDefaultDate())
                  } else {
                    setDate(e.target.value)
                  }
                }}
                disabled={scheduleChangeRequest.isPending}
              />

              {errorMessage != null && (
                <p className="text-red-500">{errorMessage}</p>
              )}
            </div>

            <div className="flex flex-col gap-y-2">
              <div className="font-medium">Opening hours</div>

              <DayTimeRangeField
                day={formattedDate}
                timeRange={openingHours}
                onTimeRangeChanged={setOpeningHours}
                disabled={scheduleChangeRequest.isPending}
              />
            </div>

            <button
              className={`mt-4 font-medium rounded-md p-2 ${scheduleChangeRequest.isPending || !areOpeningHoursValid ? "bg-gray-200 text-gray-500 opacity-75" : "bg-green-500 text-white"}`}
              onClick={() => {
                setErrorMessage(undefined)
                scheduleChangeRequest.run({ date, openingHours })}
              }
              disabled={scheduleChangeRequest.isPending || !areOpeningHoursValid}
            >
              {scheduleChangeRequest.isPending ? "Saving..." : "Add to schedule"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

function getDefaultDate(): string {
  return (addDays(new Date(), 1)).toISOString().split("T")[0]
}

async function scheduleChange(args: any[], props: any, controller: AbortController): Promise<void> {
  return GDAPI.scheduleOpeningHoursChange(args[0], controller)
}
