import { useAsync } from "react-async"
import { CheckIcon, XIcon } from "@heroicons/react/solid"

import { GDAPI } from "../../lib/GDAPI"

export function MerchantOnboardingPage() {
  const { isPending: isStatusPending, data: onboardingStatus } = useAsync({ promiseFn: GDAPI.getOnboardingStatus })
  const { isPending: isLinkPending, run } = useAsync({
    deferFn: GDAPI.getStripeOnboardingLink,
    onResolve: url => window.location.replace(url)
  })

  return (
    <div className="flex flex-col items-center bg-white shadow px-4 py-6 flex-1">
      <div className="flex flex-col max-w-sm w-full">
        <div className="text-lg font-medium mb-4">
          Stripe account onboarding
        </div>
        {onboardingStatus == null ? (
          <div>Please wait</div>
        ) : (
          <div className="flex flex-col">
            {!onboardingStatus.detailsSubmitted &&
              <div className="leading-5 mb-4">We use Stripe to process payments on Getta Delivery. You must create and connect a Stripe account before you can start accepting orders.</div>
            }
            <div className="flex flex-row items-center">
              <div className={`${onboardingStatus.detailsSubmitted ? "bg-green-200" : "bg-gray-200"} p-2 rounded-full flex flex-row items-center`}>
                {onboardingStatus.detailsSubmitted ? (
                  <CheckIcon className="h-5 w-5 text-green-800" />
                ) : (
                  <XIcon className="h-5 w-5 text-gray-600" />
                )}
              </div>
              <div className="text-lg ml-2">
                Details submitted to Stripe
              </div>
            </div>
            <div className="flex flex-row items-center mt-4">
              <div className={`${onboardingStatus.chargesEnabled ? "bg-green-200" : "bg-gray-200"} p-2 rounded-full flex flex-row items-center`}>
                {onboardingStatus.chargesEnabled ? (
                  <CheckIcon className="h-5 w-5 text-green-800" />
                ) : (
                  <XIcon className="h-5 w-5 text-gray-600" />
                )}
              </div>
              <div className="text-lg ml-2">
                Ready to accept orders
              </div>
            </div>
            <div className="flex flex-row items-center mt-4">
              <div className={`${onboardingStatus.payoutsEnabled ? "bg-green-200" : "bg-gray-200"} p-2 rounded-full flex flex-row items-center`}>
                {onboardingStatus.payoutsEnabled ? (
                  <CheckIcon className="h-5 w-5 text-green-800" />
                ) : (
                  <XIcon className="h-5 w-5 text-gray-600" />
                )}
              </div>
              <div className="text-lg leading-5 ml-2">
                Ready to receive payouts
              </div>
            </div>
            {!onboardingStatus.detailsSubmitted &&
              <>
                <div className="leading-5 mt-4">Stripe will request your personal information and bank account details for receiving funds in addition to information about your business. These details are not shared with Getta Delivery and we have no control over this process.</div>
                <button
                  type="submit"
                  className={`
                    w-full
                    p-2
                    rounded-md
                    text-lg font-medium 
                    shadow
                    mt-6
                    cursor-pointer hover:bg-brand-500-lighter hover:text-brand-700 bg-brand-600 text-white
                  `}
                  disabled={isStatusPending || isLinkPending}
                  onClick={run}
                >
                  Connect a Stripe account
                </button>
              </>
            }
            {onboardingStatus.detailsSubmitted && !onboardingStatus.chargesEnabled &&
              <div className="leading-5 mt-4">It may take some time for Stripe to approve your account. Please check back later.</div>
            }
          </div>
        )}
      </div>
    </div>
  )
}
