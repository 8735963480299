export function formatTime(time: [number, number], pad0 = false, use12h = false): string {
  const [hour, minute] = time
  return `${hour < 10 && pad0 ? "0" : ""}${use12h ? hour % 12 : hour}:${minute < 10 ? "0" : ""}${minute}${use12h ? (hour < 12 ? "am" : "pm") : ""}`
}

export function isSameTime(time1: [number, number], time2: [number, number]): boolean {
  return time1[0] === time2[0] && time1[1] === time2[1]
}

export function isAfter(time1: [number, number], time2: [number, number]): boolean {
  return time1[0] > time2[0] || (time1[0] === time2[0] && time1[1] > time2[1])
}
