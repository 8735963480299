import { FC, useContext, useState, useRef, useEffect } from "react"
import { useAsync } from "react-async"
import { Transition } from "@headlessui/react"

import GettaDeliveryLogo from "../../assets/getta_delivery_logo.png"

import { AppStateContext } from "../AppStateContextProvider"
import { TextField } from "../common/TextField"
import { useHistory } from "react-router"
import { GDAPI } from "../../lib/GDAPI"

const LogInPage: FC = () => {
  const { state: { authToken }, dispatch } = useContext(AppStateContext)

  const [emailInput, setEmailInput] = useState<string>("")
  const emailFieldRef = useRef<HTMLInputElement>(null)

  const [passwordInput, setPasswordInput] = useState<string>("")
  const passwordFieldRef = useRef<HTMLInputElement>(null)

  const [formError, setFormError] = useState<string>()

  const history = useHistory()
  const { error, isPending, run } = useAsync({ deferFn: GDAPI.logIn, dispatch, history })

  useEffect(() => {
    if (authToken != null) {
      history.replace("/products")
    }
  }, [authToken, history])

  return (
    <div className="flex flex-col items-center bg-white shadow px-4 py-6 flex-1">
      <div className="flex flex-col max-w-sm w-full">
        <img
          className="object-contain h-24 mb-6"
          src={GettaDeliveryLogo}
          alt="Getta Delivery"
        />
        <form
          onSubmit={event => {
            event.preventDefault()
            emailFieldRef.current?.blur()
            passwordFieldRef.current?.blur()
            setFormError(undefined)

            const email = emailInput.trim()

            if (email.length === 0) {
              setFormError("Please enter your email address.")
              return
            }

            const password = passwordInput

            if (password.length === 0 || password.trim().length === 0) {
              setFormError("Please enter your password.")
              return
            }

            run({ email, password })
          }}
        >
          <Transition
            show={error != null || formError != null}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-25"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="py-2 px-4 bg-red-100 text-red-600 text-base font-medium rounded text-center mb-4">
              {error?.message ?? formError}
            </div>
          </Transition>
          <TextField
            ref={emailFieldRef}
            type="email"
            name="Email address"
            value={emailInput}
            onValueChanged={setEmailInput}
            required
            className="mb-2"
            disabled={isPending}
            primary
          />
          <TextField
            ref={passwordFieldRef}
            type="password"
            name="Password"
            value={passwordInput}
            onValueChanged={setPasswordInput}
            required
            disabled={isPending}
            primary
          />
          <input
            type="submit"
            value={isPending ? "Logging in..." : "Log in"}
            className={`
              w-full
              p-2
              rounded-md
              text-lg font-medium 
              shadow
              mt-6
              ${isPending
                  ? "cursor-wait bg-gray-300 text-gray-600"
                  : "cursor-pointer bg-brand-500-lighter text-brand-700 hover:bg-brand-500 hover:text-white"
              }
            `}
            disabled={isPending}
          />
        </form>
      </div>
    </div>
  )
}

export default LogInPage
