import React, { useEffect, useMemo, useRef } from "react"
import _ from "lodash"
import * as DateFns from "date-fns"
import { ChevronRightIcon } from "@heroicons/react/solid"

import { OrderSummary } from "../../../lib/GDAPI"

interface PaginatedOrderSummariesListProps {
  orderSummaries: OrderSummary[]
  selectedOrderId?: string
  onOrderSummarySelected: (orderSummary: OrderSummary) => void
  onPageEndReached: () => void
  allResultsFetched?: boolean
  tag: string
}

const observerOptions: IntersectionObserverInit = {
  threshold: 0.5,
}

export const PaginatedOrderSummariesList: React.FC<
  PaginatedOrderSummariesListProps
> = ({
  orderSummaries,
  selectedOrderId,
  onOrderSummarySelected,
  onPageEndReached,
  allResultsFetched,
  tag,
}) => {
  const orderItems = useMemo(() => {
    return orderSummaries.map((orderSummary) => (
      <OrderSummaryItem
        key={orderSummary.id}
        orderSummary={orderSummary}
        selected={orderSummary.id === selectedOrderId}
        onSelected={onOrderSummarySelected}
      />
    ))
  }, [orderSummaries, selectedOrderId, onOrderSummarySelected])

  const loadTriggerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const observer = new IntersectionObserver((entities) => {
      if (!entities[0].isIntersecting) {
        return
      }

      onPageEndReached()
    }, observerOptions)

    if (loadTriggerRef.current != null) {
      observer.observe(loadTriggerRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [onPageEndReached])

  const scrollContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollContainerRef.current != null) {
      scrollContainerRef.current.scrollTop = 0
    }
  }, [scrollContainerRef, tag])

  return (
    <div
      className={`
        flex-1 flex flex-col overflow-y-auto
        pb-4 bg-gray-100 
      `}
      ref={scrollContainerRef}
    >
      {orderItems}

      <div
        ref={loadTriggerRef}
        className={`
          flex flex-row items-center align-center
          p-4
          text-base font-medium text-gray-600
        `}
      >
        {tag === "search" && orderSummaries.length === 0
          ? "Your search results will appear here."
          : allResultsFetched
          ? "No more results."
          : "Loading..."}
      </div>
    </div>
  )
}

interface OrderSummaryItemProps {
  orderSummary: OrderSummary
  onSelected: (order: OrderSummary) => void
  selected?: boolean
}

export const OrderSummaryItem = React.memo(function ({
  orderSummary,
  onSelected,
  selected,
}: OrderSummaryItemProps) {
  const createdAt = new Date(orderSummary.createdAt)
  const date = DateFns.format(createdAt, "d/M/yy")
  const time = DateFns.format(createdAt, "h:mm a")

  return (
    <div
      className={`
        flex flex-row items-center
        ${selected ? "bg-brand-500" : "bg-white hover:bg-brand-100"}
        border-b border-gray-100
        pl-2 py-4
        cursor-pointer
      `}
      onClick={() => onSelected(orderSummary)}
    >
      <div
        className={`
          flex flex-col items-center
          text-xs font-medium leading-3
          ${selected ? "text-gray-100" : "text-gray-500"}
        `}
      >
        <div>{date}</div>
        <div className="mt-0.5">{time}</div>
      </div>
      <div
        className={`
          text-lg font-medium ml-2 
          ${selected ? "text-white" : "text-black"}
        `}
      >
        {orderSummary.id}
      </div>
      <div
        className={`
          flex-1 text-sm font-normal leading-4 mx-4 text-center
          ${selected ? "text-gray-100" : "text-gray-800"}
        `}
      >
        {orderSummary.deliveryAddress ?? "Collection"}
      </div>
      <div className="flex flex-col items-center mr-2">
        <div
          className={`
            text-base font-medium leading-4 
            ${selected ? "text-white" : "text-black"}
          `}
        >
          £{(orderSummary.total / 100).toFixed(2)}
        </div>
        <div
          className={`
            text-xs font-medium leading-3 mt-1
            ${selected ? "text-gray-100" : "text-gray-500"}
          `}
        >
          {orderSummary.status}
        </div>
      </div>
      <ChevronRightIcon
        className={`
          md:hidden
          h-6 w-6
          mr-1
          text-brand-600
          ${selected ? "invisible" : "visible"}
        `}
      />
    </div>
  )
},
_.isEqual)
