import { Switch, Transition } from "@headlessui/react"

import { TimeRange } from "../../../../../lib/GDAPI"
import { usePrevious } from "../../../../../hooks/UsePrevious"
import { DayAvailabilityField } from "../../../../common/availability/DayAvailabilityField"

type FieldProps = {
  categoryAvailability?: TimeRange[]
  onCategoryAvailabilityChanged: (availability?: TimeRange[]) => any
  disabled?: boolean
}

export function CategoryAvailabilityField({
  categoryAvailability,
  onCategoryAvailabilityChanged,
  disabled
}: FieldProps) {
  const isEnabled = categoryAvailability != null
  const previousCategoryAvailability = usePrevious(categoryAvailability)

  return (
    <div className="border-t border-b border-blue-100 py-4 flex flex-col">
      <Switch.Group>
        <div className="flex items-center mb-2">
          <Switch.Label
            className="flex-1 cursor-pointer pr-4"
          >
            <div className="text-base font-medium text-blue-500">
              Limited availability
            </div>
            <div className="text-sm font-regular text-gray-500 leading-4 mt-1">
              Products in this category will only be available to order for a limited time.
            </div>
          </Switch.Label>
          <Switch
            as="div"
            checked={isEnabled}
            onChange={() => {
              if (disabled) {
                return
              }

              if (categoryAvailability != null) {
                onCategoryAvailabilityChanged(undefined)
              } else {
                onCategoryAvailabilityChanged(previousCategoryAvailability ?? [
                  { start: [8, 0], end: [22, 0] },
                  { start: [8, 0], end: [22, 0] },
                  { start: [8, 0], end: [22, 0] },
                  { start: [8, 0], end: [22, 0] },
                  { start: [8, 0], end: [22, 0] },
                  { start: [8, 0], end: [22, 0] },
                  { start: [8, 0], end: [22, 0] },
                ])
              }
            }}
            className={`
              ${isEnabled ? "bg-blue-500" : "bg-gray-200"}
              relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
            `}
          >
            <span
              className={`
                ${isEnabled ? "translate-x-7" : "translate-x-1"}
                inline-block w-6 h-6 bg-white rounded-full
                transform transition ease-in-out duration-200 shadow
              `}
            />
          </Switch>
        </div>
      </Switch.Group>
      <Transition
        show={isEnabled}
        enter="transition-opacity ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {categoryAvailability?.map((availability, index) => {
          return (
            <DayAvailabilityField
              key={index}
              day={DAYS_OF_WEEK[index]}
              availability={availability}
              onAvailabilityChanged={newAvailability => {
                const newCategoryAvailability = [...categoryAvailability]
                newCategoryAvailability[index] = newAvailability
                onCategoryAvailabilityChanged(newCategoryAvailability)
              }}
              disabled={disabled}
            />
          )
        })}
      </Transition>
    </div>
  )
}

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
]
