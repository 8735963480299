import React from "react"
import classNames from "clsx"

export const TabBar: React.FC = ({ children }) => {
  return (
    <div className={styles.tabBar}>
      {children}
    </div>
  )
}

interface TabBarButtonProps {
  onClick: () => any
  isActive?: boolean
}

export const TabBarButton: React.FC<TabBarButtonProps> = ({ isActive, onClick, children }) => {
  return (
    <button
      className={classNames(
        styles.tabBarButton,
        isActive ? "border-brand-500 text-brand-600" : "border-transparent text-gray-500"
      )}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

const styles = {
  tabBar: "flex-grow max-w-sm flex flex-row items-center",
  tabBarButton: "flex-grow flex flex-row items-center justify-center py-3 border-b-2 text-base font-medium hover:text-brand-600 focus:outline-none",
}
