import { useEffect, useState } from "react"
import { useAsync } from "react-async"
import * as DateFns from "date-fns"
import { useHistory } from "react-router-dom"

import { DailyRevenueSummary, GDAPI, MonthlyRevenueSummary, RevenueSummary, WeeklyRevenueSummary } from "../../../lib/GDAPI"
import { SummariesList } from "./SummariesList"

interface TabBarButtonProps {
  label: string
  onClick: () => any
  isActive?: boolean
}

function TabBarButton({ label, isActive, onClick }: TabBarButtonProps) {
  return (
    <button
      className={`
        flex-1 flex flex-row items-center justify-center py-3
        text-base font-medium ${isActive ? "text-brand-600" : "text-gray-500"} hover:text-brand-600
        border-b-2 ${isActive ? "border-brand-500" : "border-transparent"}
        focus:outline-none
      `}
      onClick={onClick}
    >
      {label}
    </button>
  )
}

enum ReportType {
  Daily,
  Weekly,
  Monthly
}

export function ReportsPage() {
  const history = useHistory()

  const [reportType, setReportType] = useState(ReportType.Daily)
  const [summaries, setSummaries] = useState<RevenueSummary[]>([])
  const [fetchedAll, setFetchedAll] = useState(false)

  const fetchSummariesRequest = useAsync({
    deferFn: fetchSummaries,
    onResolve: fetchedSummaries => {
      if (fetchedSummaries.length === 0) {
        setFetchedAll(true)
        return
      }

      setSummaries(existingSummaries => [...existingSummaries, ...fetchedSummaries])
    },
    onReject: () => {
      history.push("/")
    },
    reportType
  })

  useEffect(() => {
    setFetchedAll(false)
    setSummaries([])
  }, [reportType])

  return (
    <div className="flex flex-col flex-1 overflow-hidden">
      <div className="flex flex-row items-center justify-center bg-white shadow z-50">
        <div className="flex-1 flex flex-row items-center max-w-sm">
          <TabBarButton
            label="Daily"
            isActive={reportType === ReportType.Daily}
            onClick={() => {
              setReportType(ReportType.Daily)
            }}
          />
          <TabBarButton
            label="Weekly"
            isActive={reportType === ReportType.Weekly}
            onClick={() => {
              setReportType(ReportType.Weekly)
            }}
          />
          <TabBarButton
            label="Monthly"
            isActive={reportType === ReportType.Monthly}
            onClick={() => {
              setReportType(ReportType.Monthly)
            }}
          />
        </div>
      </div>

      <SummariesList
        summaries={summaries}
        fetchMore={() => {
          if (fetchedAll || fetchSummariesRequest.isPending) {
            return
          }

          if (summaries.length === 0) {
            fetchSummariesRequest.run()
          } else {
            const latestSummary = summaries[summaries.length - 1]
            let date = new Date()

            if (reportType === ReportType.Daily) {
              date = DateFns.subDays(new Date((latestSummary as DailyRevenueSummary).date), 1)
            } else if (reportType === ReportType.Weekly) {
              date = DateFns.subDays(new Date((latestSummary as WeeklyRevenueSummary).start), 1)
            } else {
              date = DateFns.subDays(new Date((latestSummary as MonthlyRevenueSummary).month), 1)
            }

            
            const dateString = DateFns.format(date, "yyyy-MM-dd")
            fetchSummariesRequest.run(dateString)
          }
        }}
        fetchedAll={fetchedAll}
      />
    </div>
  )
}

async function fetchSummaries(args: any[], props: any, controller: AbortController): Promise<RevenueSummary[]> {
  const [date] = args as [string | undefined]
  const { reportType } = props as { reportType: ReportType }

  switch (reportType) {
    case ReportType.Daily:
      return GDAPI.getDailyOrderSummaries(date, controller)
    case ReportType.Weekly:
      return GDAPI.getWeeklyOrderSummaries(date, controller)
    case ReportType.Monthly:
      return GDAPI.getMonthlyOrderSummaries(date, controller)
  }
}
