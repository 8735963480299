export type ScheduleAChangeButtonProps = {
  onClick?: () => void
}

export const ScheduleAChangeButton: React.FC<ScheduleAChangeButtonProps> = ({ onClick }) => {
  return (
    <button className="sm:w-1/2 sm:self-center mt-8 px-4 py-2 bg-purple-500 text-white font-medium rounded-md" onClick={onClick}>
      Schedule a change
    </button>
  )
}
