import { useEffect, useRef, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { UploadIcon, XIcon, MinusCircleIcon } from "@heroicons/react/solid"
import { ExclamationIcon } from "@heroicons/react/outline"
import Loader from "react-loader-spinner"

import { TextField } from "../../../../common/TextField"
import { useAsync } from "react-async"
import { GDAPI, Product, ProductOfferSummary, ProductOptionGroup } from "../../../../../lib/GDAPI"
import { ProductOfferField } from "./ProductOfferField"
import { CategoryPicker } from "../category/CategoryPicker"
import { ProductOutOfStockField } from "./ProductOutOfStockField"
import { ProductMaxQuantityField } from "./ProductMaxQuantityField"
import { ProductOptionsField } from "./ProductOptionsField"

type ProductDialogProps = {
  isOpen: boolean
  onClose: () => any
  onProductCreated: (product: Product) => any
  existingProduct?: Product
  onProductUpdated: (product: Product) => any
  onProductDeleted: () => any
  currentCategoryId?: number
}

async function performProductAction(args: any[], props: any, controller: AbortController) {
  const [data, productId] = args as [Partial<Product> | undefined, number | undefined]

  if (data != null && productId != null) {
    props.onProductUpdated(await GDAPI.updateProduct([data, productId], props, controller))
  } else if (productId != null) {
    props.onProductDeleted(await GDAPI.deleteProduct([productId], props, controller))
  } else {
    props.onProductCreated(await GDAPI.createProduct([data], props, controller))
  }
}

async function uploadImage(args: any[], props: any, controller: AbortController) {
  const url = await GDAPI.uploadImage(args, props, controller)
  props.onImageUploaded(url)
}

function optionsWithKeys(options: ProductOptionGroup[]): ProductOptionGroup[] {
  return options.map(group => {
    return {
      ...group,
      id: `${Date.now()}_${Math.random()}`,
      options: group.options.map(option => {
        return {
          ...option,
          id: `${Date.now()}_${Math.random()}`
        }
      })
    }
  })
}

function optionsWithoutKeys(options: ProductOptionGroup[]): ProductOptionGroup[] {
  return options.map(group => {
    delete group.id

    return {
      ...group,
      options: group.options.map(option => {
        delete option.id

        return {
          ...option,
        }
      })
    }
  })
}

export function ProductDialog({ isOpen, onClose, onProductCreated, existingProduct, onProductUpdated, onProductDeleted, currentCategoryId }: ProductDialogProps) {
  const [nameValue, setNameValue] = useState("")
  const nameInput = useRef<HTMLInputElement>(null)

  const [priceValue, setPriceValue] = useState("")
  const priceInput = useRef<HTMLInputElement>(null)

  const [barcodeValue, setBarcodeValue] = useState("")
  const barcodeInput = useRef<HTMLInputElement>(null)

  const focusRef = useRef<HTMLDivElement>(null)
  const formRef = useRef<HTMLFormElement>(null)
  const submitRef = useRef<HTMLInputElement>(null)
  const [formError, setFormError] = useState<string | undefined>()
  const [isDeleting, setIsDeleting] = useState(false)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const [uploadedImageURL, setUploadedImageURL] = useState<string>()
  const [removeExistingImage, setRemoveExistingImage] = useState(false)
  const [offer, setOffer] = useState<Partial<ProductOfferSummary> | undefined>()
  const [userError, setUserError] = useState<string | undefined>()
  const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null)

  const [outOfStockAt, setOutOfStockAt] = useState<Date>()

  const [maxQuantity, setMaxQuantity] = useState<number>()

  const [options, setOptions] = useState<ProductOptionGroup[]>()

  useEffect(() => {
    if (!isOpen) {
      setNameValue("") 
      setPriceValue("")
      setBarcodeValue("")

      setIsDeleting(false)
      setUploadedImageURL(undefined)
      setRemoveExistingImage(false)
      setOffer(undefined)
      setSelectedCategoryId(null)
      setOutOfStockAt(undefined)
      setMaxQuantity(undefined)
      setOptions(undefined)
    } else {
      setSelectedCategoryId(currentCategoryId ?? null)
    }
  }, [
    isOpen,
    setNameValue, setPriceValue, setBarcodeValue,
    setIsDeleting, setUploadedImageURL, setRemoveExistingImage, setOffer, setSelectedCategoryId, setOutOfStockAt, setMaxQuantity, setOptions,
    currentCategoryId
  ])

  useEffect(() => {
    if (existingProduct == null) {
      return
    }

    setNameValue(existingProduct.name)
    setPriceValue(existingProduct.price.toString())
    setBarcodeValue(existingProduct.barcode ?? "")
    setOffer(existingProduct.offer ?? undefined)
    setSelectedCategoryId(existingProduct.category)
    setOutOfStockAt(
      existingProduct.outOfStockAt != null
        ? new Date(existingProduct.outOfStockAt)
        : undefined
    )
    setMaxQuantity(existingProduct.maxQuantity ?? undefined)

    if (existingProduct.options != null) {
      const keyedOptions = optionsWithKeys(existingProduct.options)
      setOptions(keyedOptions)
    } else {
      setOptions(undefined)
    }
  }, [existingProduct, setNameValue, setPriceValue, setBarcodeValue, setOffer, setSelectedCategoryId, setOutOfStockAt, setMaxQuantity, setOptions])

  useEffect(() => {
    if (offer == null) {
      setUserError(undefined)
      return
    }

    if (offer.price == null || offer.price < 1 || offer.quantity == null || offer.quantity < 1) {
      setUserError("Please check your form for errors.")
      return
    }

    setUserError(undefined)
  }, [offer, setUserError])

  useEffect(() => {
    if (maxQuantity == null) {
      setUserError(undefined)
      return
    }

    if (maxQuantity <= 0) {
      setUserError("Please check your form for errors.")
      return
    }

    setUserError(undefined)
  }, [maxQuantity, setUserError])

  const { error, isPending, run } = useAsync({
    deferFn: performProductAction,
    onProductCreated,
    onProductUpdated,
    onProductDeleted
  })
  
  const imageUploader = useAsync({
    deferFn: uploadImage,
    onImageUploaded: setUploadedImageURL
  })

  const isPerformingOperation = isPending || imageUploader.isPending

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        return
      }}
      className="fixed z-10 inset-0 h-full"
      initialFocus={focusRef}
    >
      <div className="flex flex-row justify-center h-full">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div
          ref={focusRef}
          className={`
            z-20
            bg-white
            max-w-sm w-full
            md:rounded lg:rounded-lg
            shadow-lg

            flex flex-col
            sm:my-8
            overflow-hidden
          `}
        >
          <div
            className={`
              flex flex-row
              border-b border-gray-200
            `}
          >
            <Dialog.Title className="text-xl font-medium flex-1 p-4">
              {existingProduct != null
                ? isDeleting
                  ? "Delete product"
                  : "Update product"
                : "Create a product"
              }
            </Dialog.Title>
            <div className="flex flex-col">
              <button 
                className={`
                  flex-1 px-4
                  flex flex-row items-center justify-center

                  bg-gray-200
                `}
                disabled={isPerformingOperation}
                onClick={onClose}
              >
                <XIcon className="h-6 w-6 text-gray-800" />
              </button>
            </div>
          </div>
          <div className="shadow-inner flex-1 flex flex-col items-start overflow-y-auto">
            <div className="w-full px-4 pt-4 pb-16">
              {existingProduct != null && isDeleting ? (
                <>
                  <div className="text-lg font-regular text-center">
                    Are you sure you want to delete this product?
                  </div>
                  <div
                    className="border-2 border-dashed border-red-500 rounded bg-white w-full text-left p-4 flex flex-row items-center my-4"
                  >
                    <div className="w-16 h-16 rounded-lg">
                      {existingProduct.image != null ? (
                        <img
                          src={existingProduct.image}
                          className="w-16 h-16 object-contain rounded-lg"
                          alt={existingProduct.name}
                        />
                      ) : (
                        <div className="w-full h-full rounded-md border-2 border-dashed border-blue-200 flex justify-center items-center">
                          <div className="hidden text-xs text-blue-200 font-medium text-center">
                            Product Image
                          </div>
                        </div>
                      )}
                    </div>        
                    <div className="flex-1 flex flex-col ml-3 mr-4">
                      <div className="text-base font-regular text-gray-800">
                        {existingProduct.name}
                      </div>
                      <div className="text-base font-medium text-gray-900 mt-1">
                        £{(existingProduct.price / 100).toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center justify-center p-4 bg-red-50 border border-red-500 rounded-lg text-red-500">
                    <ExclamationIcon className="w-6 h-6 text-red-500 mr-3" />
                    <div className="flex-1 text-sm font-regular">
                      <span className="font-medium">This action cannot be undone.</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {existingProduct != null &&
                    <button
                      onClick={() => setIsDeleting(true)}
                      className={`
                        w-full
                        flex flex-row items-center justify-center
                        py-2 px-4
                        bg-red-100 text-red-800
                        rounded-lg shadow-sm
                        border border-transparent
                        mt-2 mb-8
                      `}
                    >
                      <MinusCircleIcon className="w-5 h-5 mr-1" />
                      <div className="text-base font-medium">
                        Delete product
                      </div>
                    </button>
                  }
                  <div className="flex flex-row justify-center mt-4 mb-8">
                    <div className="w-24 h-24 rounded-lg mr-8 flex items-center justify-center">
                      {uploadedImageURL != null || (existingProduct?.image != null && !removeExistingImage) ? (
                        <img
                          src={uploadedImageURL ?? existingProduct?.image!}
                          className="w-full h-full object-contain rounded-lg"
                          alt={existingProduct?.name}
                        />
                      ) : (
                        imageUploader.isPending ? (
                          <div className="w-full h-full flex items-center justify-center">
                            <Loader
                              type="Oval"
                              color="#10B981"
                              width={48}
                              height={48}
                            />
                          </div>
                        ) : (
                          <div className="w-full h-full rounded-lg border-4 border-blue-200 border-dashed flex items-center justify-center">
                            <div className="text-base font-medium text-blue-200 text-center">
                              Product Image
                            </div>
                          </div>
                        )
                      )}
                    </div>
                    <div className="flex flex-col justify-center">
                      <input
                        type="file"
                        name="image"
                        accept="image/jpeg,image/png,image/webp,image/svg+xml"
                        className="hidden"
                        ref={fileInputRef}
                        onChange={e => {
                          if (e.target.files === null || e.target.files.length === 0) {
                            return
                          }

                          setUploadedImageURL(undefined)

                          const file = e.target.files[0]
                          imageUploader.run(file)
                        }}
                      />
                      <button
                        onClick={() => fileInputRef.current?.click()}
                        className={`
                          flex flex-row items-center justify-center
                          py-2 px-4
                          bg-blue-100 text-blue-800
                          rounded-lg shadow-sm
                          border border-transparent
                        `}
                      >
                        <UploadIcon className="w-5 h-5 mr-1" />
                        <div className="text-sm font-medium">
                          Upload image
                        </div>
                      </button>
                      {(existingProduct?.image != null || uploadedImageURL != null) &&
                        <button
                          onClick={() => {
                            if (uploadedImageURL != null) {
                              setUploadedImageURL(undefined)
                            } else {
                              setRemoveExistingImage(true)
                            }
                          }}
                          className={`
                            flex flex-row items-center justify-center
                            py-2 px-4
                            bg-red-100 text-red-800
                            rounded-lg shadow-sm
                            border border-transparent
                            mt-4
                          `}
                        >
                          <XIcon className="w-5 h-5 mr-1" />
                          <div className="text-sm font-medium">
                            Remove image
                          </div>
                        </button>
                      }
                    </div>
                  </div>
    
                  <form
                    ref={formRef}
                    autoComplete="off"
                    onSubmit={event => {
                      event.preventDefault()

                      if (userError != null || isPerformingOperation) {
                        return
                      }

                      nameInput.current?.blur()
                      priceInput.current?.blur()
                      setFormError(undefined)

                      if (selectedCategoryId == null) {
                        setFormError("Please select a category.")
                        return
                      }
    
                      const name = nameValue.trim()
    
                      if (name.length === 0) {
                        setFormError("Please provide a name.")
                        return
                      }

                      const price = parseInt(priceValue.trim())

                      if (isNaN(price) || price < 0) {
                        setFormError("Please provide a valid price.")
                        return
                      }

                      let barcode: string | null = barcodeValue.trim()

                      if (barcode.length === 0) {
                        barcode = null
                      }

                      const productData = {
                        categoryId: selectedCategoryId,
                        name,
                        price,
                        image: removeExistingImage && uploadedImageURL == null
                          ? null
                          : uploadedImageURL,
                        barcode,
                        offer: offer ?? null,
                        outOfStock: outOfStockAt != null,
                        maxQuantity: maxQuantity ?? null,
                        options: options == null
                          ? null
                          : optionsWithoutKeys(options),
                      }
    
                      run(productData, existingProduct?.id)
                    }}
                  >
                    <CategoryPicker
                      label="Category"
                      required
                      selectedCategoryId={selectedCategoryId}
                      onCategorySelected={setSelectedCategoryId}
                      className="mb-4"
                    />
                    
                    <TextField
                      name="Name"
                      value={nameValue}
                      onValueChanged={setNameValue}
                      required
                      disabled={isPerformingOperation}
                      ref={nameInput}
                      className="mb-4"
                      onSubmit={() => {
                        priceInput.current?.focus()
                      }}
                    />

                    <TextField
                      name="Price (Pence)"
                      type="number"
                      min={0}
                      value={priceValue}
                      onValueChanged={setPriceValue}
                      required
                      disabled={isPerformingOperation}
                      ref={priceInput}
                      className="mb-4"
                      onSubmit={() => {
                        barcodeInput.current?.focus()
                      }}
                    />

                    <TextField
                      name="Barcode - Optional"
                      type="string"
                      value={barcodeValue}
                      onValueChanged={setBarcodeValue}
                      disabled={isPerformingOperation}
                      ref={barcodeInput}
                      className="mb-4"
                      onSubmit={() => {
                        barcodeInput.current?.blur()
                      }}
                    />

                    <ProductOptionsField
                      optionGroups={options}
                      onOptionGroupsChanged={updatedOptions => {
                        setOptions(updatedOptions)
                      }}
                      disabled={isPerformingOperation}
                    />

                    <ProductMaxQuantityField
                      maxQuantity={maxQuantity}
                      onMaxQuantityChanged={setMaxQuantity}
                      disabled={isPerformingOperation}
                    />

                    <ProductOfferField
                      offer={offer}
                      onOfferChanged={setOffer}
                      disabled={isPerformingOperation}
                    />

                    <ProductOutOfStockField
                      outOfStockAt={outOfStockAt}
                      onOutOfStockAtChanged={setOutOfStockAt}
                      disabled={isPerformingOperation}
                    />
    
                    <input
                      type="submit"
                      className="hidden"
                      ref={submitRef}
                    />
                  </form>
                </>
              )}
            </div>
          </div>
          <div className="w-full flex flex-col p-4 border-t border-gray-200">
            <Transition
              show={error != null || formError != null}
              enter="transition-opacity duration-150"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity duration-25"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="py-2 px-4 bg-red-100 text-red-600 text-base font-medium rounded text-center mb-4">
                {error?.message ?? formError}
              </div>
            </Transition>
            <div className="flex flex-row">
              <button
                onClick={() => {
                  if (isDeleting) {
                    setIsDeleting(false)
                  } else {
                    onClose()
                  }
                }}
                disabled={isPerformingOperation}
                className={`
                  flex-1
                  py-2 px-4
                  mr-4
                  bg-gray-200
                  border border-transparent
                  text-gray-700 text-base font-medium
                  rounded-lg shadow
                `}
              >
                {isDeleting ? "No" : "Cancel"}
              </button>
              <button
                onClick={() => {
                  if (existingProduct != null && isDeleting) {
                    run(undefined, existingProduct.id)
                    return
                  }

                  submitRef.current?.click()
                }}
                disabled={userError != null || isPerformingOperation}
                className={`
                  flex-1
                  flex flex-row items-center justify-center
                  py-2 px-4
                  ml-4
                  
                  ${isPending
                    ? isDeleting
                      ? "bg-red-700"
                      : existingProduct != null
                        ? "bg-yellow-500"
                        : "bg-green-500"
                    : isDeleting
                      ? "bg-red-500"
                      : existingProduct != null
                        ? "bg-yellow-200"
                        : "bg-green-200"
                  }

                  border border-transparent
                  rounded-lg shadow
                `}
              >
                {isPending &&
                  <div className="mr-2">
                    <Loader
                      type="Oval"
                      color="#FFFFFF"
                      width={20}
                      height={20}
                    />
                  </div>
                }
                <div
                  className={`
                    text-base font-medium
                    ${isPending || isDeleting ? "text-white" : existingProduct != null ? "text-yellow-800" : "text-green-800"}
                  `}
                >
                  {isPending
                    ? isDeleting
                      ? "Deleting..."
                      : existingProduct != null
                        ? "Updating..."
                        : "Creating..."
                    : isDeleting
                      ? "Yes, delete"
                      : existingProduct != null
                        ? "Update"
                        : "Create"
                  }
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}