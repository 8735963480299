import { useEffect, useRef, useState } from "react"
import _ from "lodash"

import { TimeRange } from "../../../lib/GDAPI"
import { TimePartInput } from "./TimePartInput"

type DayAvailabilityFieldProps = {
  day: string
  availability: TimeRange
  onAvailabilityChanged: (availability: TimeRange) => any
  disabled?: boolean
}

export function DayAvailabilityField({ day, availability, onAvailabilityChanged, disabled }: DayAvailabilityFieldProps) {
  const fromHourInput = useRef<HTMLInputElement>(null)
  const fromMinuteInput = useRef<HTMLInputElement>(null)

  const untilHourInput = useRef<HTMLInputElement>(null)
  const untilMinuteInput = useRef<HTMLInputElement>(null)

  const [fromHourValue, setFromHourValue] = useState(availability.start[0].toString())
  const [fromMinuteValue, setFromMinuteValue] = useState(availability.start[1].toString())

  const [untilHourValue, setUntilHourValue] = useState(availability.end[0].toString())
  const [untilMinuteValue, setUntilMinuteValue] = useState(availability.end[1].toString())

  useEffect(() => {
    const fromHour = parseInt(fromHourValue)
    const fromMinute = parseInt(fromMinuteValue)
    const untilHour = parseInt(untilHourValue)
    const untilMinute = parseInt(untilMinuteValue)

    let start: [number, number] | undefined
    let end: [number, number] | undefined

    if (!isNaN(fromHour) && !isNaN(fromMinute)) {
      start = [fromHour, fromMinute]
    }

    if (!isNaN(untilHour) && !isNaN(untilMinute)) {
      end = [untilHour, untilMinute]
    }

    if (start == null || end == null) {
      return
    }

    const newAvailability: TimeRange = { start, end } 

    if (_.isEqual(newAvailability, availability)) {
      return
    }

    onAvailabilityChanged(newAvailability)
  }, [
    fromHourValue,
    fromMinuteValue,
    untilHourValue,
    untilMinuteValue,
    onAvailabilityChanged,
    availability
  ])

  return (
    <div className="border-t border-b border-blue-100 py-2 flex flex-col">
      <div className="flex flex-row items-center">
        <div className="flex flex-1 font-medium text-base">
          {day}
        </div>

        <div className="flex-1 flex flex-col mr-4">
          <label
            htmlFor="from"
            className="text-sm font-medium text-blue-500"
          >
            From
          </label>

          <div className="flex flex-row items-center">
            <TimePartInput
              value={fromHourValue}
              onValueChanged={setFromHourValue}
              max={23}
              disabled={disabled}
              ref={fromHourInput}
              onSubmit={() => {
                fromMinuteInput.current?.focus()
              }}
            />

            <div className="text-xl font-medium text-black px-1 h-full leading-9">:</div>

            <TimePartInput
              value={fromMinuteValue}
              onValueChanged={setFromMinuteValue}
              max={59}
              disabled={disabled}
              ref={fromMinuteInput}
              onSubmit={() => {
                untilHourInput.current?.focus()
              }}
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="from"
            className="text-sm font-medium text-blue-500"
          >
            Until
          </label>

          <div className="flex flex-row items-center">
            <TimePartInput
              value={untilHourValue}
              onValueChanged={setUntilHourValue}
              max={23}
              disabled={disabled}
              ref={untilHourInput}
              onSubmit={() => {
                untilMinuteInput.current?.focus()
              }}
            />

            <div className="text-xl font-medium text-black px-1 h-full leading-9">:</div>
            
            <TimePartInput
              value={untilMinuteValue}
              onValueChanged={setUntilMinuteValue}
              max={59}
              disabled={disabled}
              ref={untilMinuteInput}
              onSubmit={() => {
                untilMinuteInput.current?.blur()
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}