import { Dispatch, createContext, FC, useReducer } from "react"
import { GDAPI } from "../lib/GDAPI"

type AppState = {
  authToken: string | null
  accountType: 'admin' | 'merchant' | null
}

type AppStateAction = 
  | { type: "setAuthToken", authToken: string | null }
  | { type: "setAccountType", accountType: 'admin' | 'merchant' }

const initialState: AppState = {
  authToken: GDAPI.authToken,
  accountType: GDAPI.accountType
}

function appStateReducer(state: AppState, action: AppStateAction): AppState {
  switch (action.type) {
    case "setAuthToken":
      GDAPI.authToken = action.authToken
      return { ...state, authToken: action.authToken }
    case "setAccountType":
      GDAPI.accountType = action.accountType
      return { ...state, accountType: action.accountType }
    default:
      return state
  }
}

export type AppStateDispatch = Dispatch<AppStateAction>

export const AppStateContext = createContext<{
  state: AppState,
  dispatch: AppStateDispatch
}>({ state: initialState, dispatch: () => {} })

export const AppStateContextProvider: FC = ({ children }) => {
  const [state, dispatch] = useReducer(appStateReducer, initialState)

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>
      {children}
    </AppStateContext.Provider>
  )
}
