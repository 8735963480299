import React, { useCallback, useState } from "react"
import { useAsync } from "react-async"

import { DeliveryStaffMember, GDAPI } from "../../../lib/GDAPI"
import { AddDeliveryStaffModal } from "./modals/add-delivery-staff-modal"
import { ConfirmRemoveDeliveryStaffModal } from "./modals/confirm-remove-delivery-staff-modal"

export const DeliveryStaffManager: React.FC = () => {
  const [isAddStaffModalOpen, setAddStaffModalOpen] = useState(false)
  const onAddStaffModalClose = useCallback(() => setAddStaffModalOpen(false), [setAddStaffModalOpen])
  const onAddStaffMemberClick = useCallback(() => setAddStaffModalOpen(true), [setAddStaffModalOpen])

  const [staffMemberForRemoval, setStaffMemberForRemoval] = useState<DeliveryStaffMember>()

  const { data: staffMembers, reload: reloadStaffMembers, isPending } = useAsync({ promiseFn: getDeliveryStaff })

  if (!staffMembers) {
    return null
  }

  const staffMemberRows = staffMembers.map((staffMember) => (
    <tr key={staffMember.name} className={styles.staffTableRow}>
      <td className={styles.staffTableMemberCell}>
        <div className={styles.staffMemberName}>{staffMember.name}</div>
        <div className={styles.staffMemberPhoneNumber}>{staffMember.phoneNumber}</div>
      </td>
      <td className={styles.staffTableActionsCell}>
        <span className={styles.actionsContainer}>
          <button
            className={styles.deleteAction}
            onClick={() => setStaffMemberForRemoval(staffMember)}
            disabled={isPending}
          >
            Remove
          </button>
        </span>
      </td>
    </tr>
  ))

  return (
    <>
      {isAddStaffModalOpen && (
        <AddDeliveryStaffModal
          visible
          onClose={onAddStaffModalClose}
          onStaffMemberAdded={() => {
            reloadStaffMembers()
            setAddStaffModalOpen(false)
          }}
        />
      )}

      {staffMemberForRemoval != null && (
        <ConfirmRemoveDeliveryStaffModal
          visible
          onClose={() => setStaffMemberForRemoval(undefined)}
          onStaffMemberRemoved={() => {
            reloadStaffMembers()
            setStaffMemberForRemoval(undefined)
          }}
          staffMember={staffMemberForRemoval}
        />
      )}

      <div className={styles.container}>
        <table className={styles.staffTable}>
          <thead>
            <tr className={styles.staffTableHeaderRow}>
              <th className={styles.staffTableHeaderCell} colSpan={2}>
                <span className={styles.staffTableHeadingContainer}>
                  <span>Staff members</span>
                  <span
                    className={styles.addStaffMemberAction}
                    onClick={onAddStaffMemberClick}
                  >
                    Add delivery staff
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {staffMemberRows}
          </tbody>
        </table>
      </div>
    </>
  )
}

const styles = {
  container: "flex flex-col py-4",
  heading: "font-medium text-lg",
  staffTable: "table-auto w-full",
  staffTableHeadingContainer: "flex flex-row items-center justify-between font-medium",
  addStaffMemberAction: "font-normal cursor-pointer hover:underline py-1.5 px-3 rounded-md bg-brand-500 text-white",
  staffTableHeaderRow: "border-b border-gray-100 h-14",
  staffTableHeaderCell: "px-4",
  staffTableRow: "border-b border-gray-100 h-16 hover:bg-gray-100",
  staffTableMemberCell: "pl-4",
  staffMemberName: "",
  staffMemberPhoneNumber: "text-sm font-light",
  staffTableActionsCell: "pr-4",
  actionsContainer: "flex flex-row items-center justify-end gap-x-8",
  deleteAction: "font-normal cursor-pointer hover:underline py-1.5 px-3 rounded-md bg-red-500 text-white"
}

async function getDeliveryStaff(props: any, controller: AbortController): Promise<DeliveryStaffMember[]> {
  return GDAPI.getDeliveryStaff(controller)
}
