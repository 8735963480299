import { forwardRef } from "react"

export type InputProps = {
  value: string
  onValueChanged: (value: string) => any
  max: number
  disabled?: boolean
  onSubmit?: () => any
  validInputOnly?: boolean
}

export const TimePartInput = forwardRef<HTMLInputElement, InputProps>(({
  value, onValueChanged, max, disabled, onSubmit, validInputOnly
}, ref) => {
  const parsedValue = parseInt(value)
  const hasError = isNaN(parsedValue) || parsedValue < 0 || parsedValue > max

  const valueText = !isNaN(parsedValue)
    ? parsedValue < 10
      ? `0${parsedValue}`
      : `${parsedValue}`
    : value

  return (
    <input
      type="number"
      className={`
        w-12 text-center
        appearance-none border rounded py-2 text-base
        text-black
        shadow
        ${disabled 
          ? "border-gray-500 opacity-50" 
          : hasError
            ? "border-red-500"
            : "border-blue-500"
        }
        ${hasError ? "bg-red-100" : "bg-white"}
      `}
      value={valueText}
      onChange={event => {
        if (validInputOnly) {
          const newValue = parseInt(event.target.value)
          const isValid = !isNaN(newValue) && newValue >= 0 && newValue <= max

          if (!isValid) {
            return
          }
        }

        onValueChanged(event.target.value)
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          e.preventDefault()
          
          if (onSubmit) {
            onSubmit()
          }
        }
      }}
      disabled={disabled}
      required
      min={0}
      max={max}
      ref={ref}
    />
  )
})
