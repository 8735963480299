import { Dialog } from "@headlessui/react"

import { OrderDetails } from "../../../../lib/GDAPI"
import { RefundDetails } from "../../../../models/RefundDetails"

export type UnableToReverseTransferModalProps = {
  onClose: () => void
  order: OrderDetails
  refundDetails: RefundDetails
}

export const UnableToReverseTransferModal: React.FC<
  UnableToReverseTransferModalProps
> = ({ onClose, order, refundDetails }) => {
  return (
    <Dialog open onClose={() => {}} className="fixed z-10 inset-0">
      <div className="flex flex-col items-center justify-center h-full">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div
          className="w-full flex flex-col overflow-y-auto z-20 bg-white md:shadow-lg rounded-lg p-6"
          style={{ maxWidth: "544px" }}
        >
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row justify-between">
              <Dialog.Title className="font-medium">
                Unable to reverse transfer
              </Dialog.Title>
            </div>

            <p>
              The customer has been refunded but Iona House Off Licence does not
              have sufficient funds in their Stripe balance to cover their
              portion. This amount will have to be collected from them
              separately.
            </p>

            <div className="flex flex-col">
              <div className="font-medium text-sm">
                Amount to collect from Iona House Off Licence
              </div>

              <div className="text-lg">
                {refundDetails.merchantAmount != null
                  ? `£${(refundDetails.merchantAmount / 100).toFixed(2)}`
                  : ""}
              </div>
            </div>

            <button
              className={`font-medium rounded-md p-2 bg-green-500 text-white`}
              onClick={onClose}
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}
