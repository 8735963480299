import { FC } from "react"
import { BrowserRouter } from "react-router-dom"

import { AppStateContextProvider } from "./components/AppStateContextProvider"
import AppContainer from "./components/AppContainer"

const App: FC = () => {
  return (
    <AppStateContextProvider>
      <BrowserRouter>
        <AppContainer />
      </BrowserRouter>
    </AppStateContextProvider>
  )
}

export default App
