import React, { useState } from "react"

import { TabBar, TabBarButton } from "../../common/tab-bar"
import { ShopStaffManager } from "./shop-staff-manager"
import { DeliveryStaffManager } from "./delivery-staff-manager"

export const StaffManagementPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<"shop" | "delivery">("shop")

  return (
    <div className={styles.page}>
      <div className={styles.tabBar}>
        <TabBar>
          <TabBarButton
            onClick={() => setSelectedTab("shop")}
            isActive={selectedTab === "shop"}
          >
            Shop staff
          </TabBarButton>

          <TabBarButton
            onClick={() => setSelectedTab("delivery")}
            isActive={selectedTab === "delivery"}
          >
            Delivery staff
          </TabBarButton>
        </TabBar>
      </div>

      <div className={styles.tabContainer}>
        <div className={styles.tabContent}>
          {selectedTab === "shop" ? (
            <ShopStaffManager />
          ) : (
            <DeliveryStaffManager />
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  page: "flex-grow flex flex-col bg-white overflow-y-hidden",
  tabBar: "flex flex-row items-center justify-center bg-white shadow z-10",
  tabContainer: "flex flex-col w-full overflow-y-auto",
  tabContent: "w-full max-w-2xl self-center"
}
