import { Listbox } from "@headlessui/react"
import { SelectorIcon } from "@heroicons/react/solid"
import { useMemo } from "react"
import { useAsync } from "react-async"

import { GDAPI, ProductCategoryListItem } from "../../../../../lib/GDAPI"

type CategoryOptionProps = {
  category: ProductCategoryListItem
  depth: number
}

function CategoryOption({ category, depth }: CategoryOptionProps) {
  return (
    <Listbox.Option value={category.id}>
      {({ active, selected }) => (
        <div
          className={`
            text-base
            ${selected ? "font-medium text-blue-500" : "font-regular"}
            pr-2 py-2
            border-b border-gray-300
            bg-white hover:bg-blue-50 ${active ? "bg-blue-50" : ""}
            cursor-pointer
          `}
          style={{ paddingLeft: (depth + 1) * 8 }}
        >
          {category.name}
        </div>
      )}
    </Listbox.Option>
  )
}

type CategoryPickerProps = {
  className?: string
  label: string
  selectedCategoryId: number | null
  onCategorySelected: (categoryId: number | null) => void
  required?: boolean
  hiddenCategories?: number[]
}

async function getCategories(props: any, controller: AbortController): Promise<ProductCategoryListItem[]> {
  return GDAPI.getCategoryList(controller)
}

function generateOptionItems(items: JSX.Element[], hiddenCategories: number[] | undefined, categories: ProductCategoryListItem[], depth: number = 0) {
  for (const category of categories) {
    if (hiddenCategories?.includes(category.id)) {
      continue
    }

    items.push(<CategoryOption key={category.id} category={category} depth={depth} />)
    generateOptionItems(items, hiddenCategories, category.subcategories, depth + 1)
  }
}

function findSelectedCategory(categories: ProductCategoryListItem[],  selectedCategoryId: number): ProductCategoryListItem | undefined {
  for (const category of categories) {
    if (category.id === selectedCategoryId) {
      return category
    }

    const selectedCategory = findSelectedCategory(category.subcategories, selectedCategoryId)

    if (selectedCategory != null) {
      return selectedCategory
    }
  }

  return undefined
}

export function CategoryPicker({
  className,
  label,
  selectedCategoryId,
  onCategorySelected,
  required,
  hiddenCategories
}: CategoryPickerProps) {
  // TODO: Add spinner
  const { data: categories } = useAsync({ promiseFn: getCategories })

  const options = useMemo(() => {
    if (categories == null) {
      return []
    }

    const items: JSX.Element[] = []
    generateOptionItems(items, hiddenCategories, categories)

    return items
  }, [categories, hiddenCategories])

  const selectedCategory = useMemo(() => {
    if (selectedCategoryId == null || categories == null) {
      return undefined
    }

    return findSelectedCategory(categories, selectedCategoryId)
  }, [selectedCategoryId, categories])

  return (
    <div className={`${className}`}>
      <Listbox
        value={selectedCategoryId}
        onChange={onCategorySelected}
      >
        <div className={`flex flex-col`}>
          <Listbox.Label className="text-base font-medium text-blue-500 mb-2">
            {label}
          </Listbox.Label>
          <Listbox.Button
            className={`border border-blue-500 rounded py-2 shadow flex flex-row items-center ${selectedCategory != null ? "text-black" : "text-gray-400"}`}
          >
            <div className="flex-1 text-left mx-2">
              {selectedCategory?.name ?? "None"}
            </div>
            <SelectorIcon className="w-5 h-5 text-blue-500 mr-1" />
          </Listbox.Button>
        </div>
        <Listbox.Options
          className="flex flex-col z-10 max-h-40 md:max-h-60 overflow-y-auto bg-white rounded border border-blue-500 shadow-md"
        >
          {!required &&
            <Listbox.Option
              value={null}
            >
              {({ active, selected }) => (
                <div className={`text-base font-regular text-gray-400 p-2 border-b border-gray-300 hover:bg-blue-50 cursor-pointer`}>
                  None
                </div>
              )}
            </Listbox.Option>
          }
          {options}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
