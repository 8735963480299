import { PencilIcon } from "@heroicons/react/solid"

import { Product } from "../../../lib/GDAPI"
import { ProductOfferLabel } from "./dialogs/product/ProductOfferLabel"
import { ProductOutOfStockLabel } from "./ProductOutOfStockLabel"

type Props = {
  product: Product
  onEditPressed: (product: Product) => void
}

export function ProductItem({ product, onEditPressed }: Props) {
  return (
    <div
      key={product.id}
      className="border-b border-blue-100 bg-white w-full text-left p-4 flex flex-row items-center"
    >
      <div className="w-10 h-10 md:w-16 md:h-16 rounded-lg">
        {product.image != null ? (
          <img
            src={product.image}
            className="w-10 h-10 md:w-16 md:h-16 object-contain rounded-lg"
            alt={product.name}
          />
        ) : (
          <div className="w-full h-full rounded-md border-2 border-dashed border-blue-200 flex justify-center items-center">
            <div className="hidden text-xs text-blue-200 font-medium text-center">
              Product Image
            </div>
          </div>
        )}
      </div>        
      <div className="flex-1 flex flex-col ml-3 mr-4">
        <div className="text-base font-regular text-gray-800">
          {product.name}
        </div>
        <div className="flex flex-row items-center mt-1">
          <div className={`text-base font-medium ${product.offer?.quantity === 1 ? "text-gray-400 line-through" : "text-gray-900"}`}>
            £{(product.price / 100).toFixed(2)}
          </div>
          {product.outOfStockAt != null &&
            <ProductOutOfStockLabel
              outOfStockAt={product.outOfStockAt}
              className="ml-2"
            />
          }
          {product.offer != null && product.outOfStockAt == null &&
            <ProductOfferLabel
              offer={product.offer}
              className="ml-2"
            />
          }
        </div>
      </div>
      <button
        className="bg-yellow-200 p-2 rounded-full shadow-sm flex flex-row items-center self-center"
        onClick={() => onEditPressed(product)}
      >
        <PencilIcon className="h-5 w-5 text-yellow-800" />
      </button>
    </div>
  )
}
