import React, { useCallback, useState } from "react"
import { Dialog } from "@headlessui/react"

import { TextField } from "../../../common/TextField"
import { GDAPI } from "../../../../lib/GDAPI"
import { useAsync } from "react-async"
import { capitalize } from "lodash"

type Props = {
  visible?: boolean
  onClose: () => void
  onStaffMemberAdded: () => void
  existingStaffMembers: string[]
}

export const AddShopStaffModal: React.FC<Props> = ({ visible, onClose, onStaffMemberAdded, existingStaffMembers }) => {
  const [staffMemberName, setStaffMemberName] = useState("")
  const [errorMessage, setErrorMessage] = useState<string>()

  const { run, isPending } = useAsync({
    deferFn: updateShopStaff,
    onResolve: () => {
      onStaffMemberAdded()
    }
  })

  const onDialogClosed = useCallback(() => {
    if (isPending) {
      return
    }

    onClose()
  }, [isPending, onClose])

  const onAddClicked = useCallback(() => {
    const staffMember = capitalize(staffMemberName.trim())

    if (staffMember.length === 0) {
      setErrorMessage("Please provide a name.")
      return
    }

    if (existingStaffMembers.some(existingStaffMember => existingStaffMember.toLowerCase() === staffMember.toLowerCase())) {
      setErrorMessage("A staff member with that name already exists.")
      return
    }

    setErrorMessage(undefined)
    run([staffMember, ...existingStaffMembers])
  }, [staffMemberName, setErrorMessage, existingStaffMembers, run])

  return (
    <Dialog
      open={visible}
      onClose={onDialogClosed}
      className={styles.root}
    >
      <div className={styles.container}>
        <Dialog.Overlay className={styles.overlay} />

        <div className={styles.modal}>
          <div className={styles.content}>
            <div className={styles.header}>
              <Dialog.Title className={styles.title}>Add shop staff</Dialog.Title>

              <button
                className={styles.cancelButton}
                onClick={onClose}
                disabled={isPending}
              >
                Cancel
              </button>
            </div>
            

            <TextField
              className={styles.nameTextField}
              name="Name"
              value={staffMemberName}
              onValueChanged={setStaffMemberName}
              primary
              required
              disabled={isPending}
            />

            {errorMessage != null && (
              <p className={styles.errorMessage}>{errorMessage}</p>
            )}

            <button
              className={styles.addButton}
              onClick={onAddClicked}
              disabled={isPending}
            >
              Add staff member
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

const styles = {
  root: "fixed z-10 inset-0",
  container: "flex flex-col items-center justify-end md:justify-center h-full",
  overlay: "fixed inset-0 bg-black opacity-50",
  modal: "w-full max-w-sm flex flex-col overflow-hidden z-20 bg-white md:shadow-lg rounded-t-lg md:rounded-md lg:rounded-lg p-4 md:p-6",
  header: "flex flex-row justify-between",
  title: "font-medium",
  cancelButton: "text-red-600 hover:underline",
  content: "flex flex-col gap-y-4 md:gap-y-6",
  addButton: "w-full py-1.5 rounded-md bg-brand-600 hover:bg-brand-500 text-white",
  nameTextField: "",
  errorMessage: "text-red-600"
}

async function updateShopStaff(args: any[], props: any, controller: AbortController) {
  const [shopStaff] = args as [string[]]
  return GDAPI.updateShopStaff(shopStaff, controller)
}
