export type SaveOpeningHoursChangesButtonProps = {
  onClick: () => any
  disabled?: boolean
  saving?: boolean
}

export const SaveOpeningHoursChangesButton: React.FC<SaveOpeningHoursChangesButtonProps> = ({ onClick, disabled, saving }) => {
  return (
    <button
      className={`my-8 sm:w-1/2 sm:self-center px-4 py-2 rounded-lg font-medium text-lg ${disabled || saving ? `bg-gray-200 text-gray-500 opacity-75` : `bg-green-500 text-white`}`}
      onClick={onClick}
      disabled={disabled || saving}
    >
      {saving ? "Saving..." : "Save changes"}
    </button>
  )
}
