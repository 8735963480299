import { ClockIcon } from "@heroicons/react/solid"
import * as DateFns from "date-fns"

import { TimeRange } from "../../../../../lib/GDAPI"

type Props = {
  availableBetween: TimeRange[]
  isFullSize?: boolean
  className?: string
}

function parseTime(time: [number, number]) {
  const [h, m] = time
  return `${h < 10 ? `0${h}` : h}:${m < 10 ? `0${m}` : m}`
}

export function CategoryAvailabilityLabel({ availableBetween, className, isFullSize }: Props) {
  const dayIndex = parseInt(DateFns.format(new Date(), "i")) - 1
  const startTime = parseTime(availableBetween[dayIndex].start)
  const endTime = parseTime(availableBetween[dayIndex].end)

  return (
    <div className={`${isFullSize ? "px-4 py-2" : "px-1.5 py-1 self-start rounded-md"} bg-yellow-300 flex flex-row items-center ${className}`}>
      <ClockIcon className="h-5 w-5 text-yellow-800 mr-1" />    
      <div className="text-sm text-yellow-800 font-medium">
        {isFullSize ? "Available to order from " : ""}{startTime} - {endTime}
      </div>
    </div>
  )
}
