import { useState } from "react"
import _ from "lodash"
import { useAsync } from "react-async"

import { DeliveryFeeSettings } from "../../../../models/DeliveryFeeSettings"
import { GDAPI } from "../../../../lib/GDAPI"
import { DeliveryFeeSettingsEditor } from "./DeliveryFeeSettingsEditor"

export type DeliveriesSettingsProps = {}

export const DeliveriesSettings: React.FC<DeliveriesSettingsProps> = () => {
  const [deliveryFeeSettings, setDeliveryFeeSettings] = useState<DeliveryFeeSettings>()

  const getDeliveryFeeSettingsRequest = useAsync({
    promiseFn: fetchDeliveryFeeSettings,
    onResolve: setDeliveryFeeSettings
  })

  const updateDeliveryFeeSettingsRequest = useAsync({
    deferFn: updateDeliveryFeeSettings,
    onResolve: getDeliveryFeeSettingsRequest.reload
  })

  const isPending = getDeliveryFeeSettingsRequest.isPending || updateDeliveryFeeSettingsRequest.isPending
  const isSaveButtonDisabled = isPending || _.isEqual(deliveryFeeSettings, getDeliveryFeeSettingsRequest.data)

  return (
    <div className="flex flex-col">
      <div className="text-lg font-medium mb-4">Delivery fee settings</div>

      {deliveryFeeSettings != null ? (
        <>
          <DeliveryFeeSettingsEditor
            deliveryFeeSettings={deliveryFeeSettings}
            onChange={setDeliveryFeeSettings}
            disabled={isPending}
          />

          <button
            className={`my-8 sm:w-1/2 sm:self-center px-4 py-2 rounded-lg font-medium text-lg ${isSaveButtonDisabled ? `bg-gray-200 text-gray-500 opacity-75` : `bg-green-500 text-white`}`}
            onClick={() => updateDeliveryFeeSettingsRequest.run(deliveryFeeSettings)}
            disabled={isSaveButtonDisabled}
          >
            {updateDeliveryFeeSettingsRequest.isPending ? "Saving..." : "Save changes"}
          </button>
        </>
      ) : (
        <div className="text-center text-gray-500">Loading...</div>
      )}
    </div>
  )
}

async function fetchDeliveryFeeSettings(props: any, controller: AbortController): Promise<DeliveryFeeSettings> {
  return GDAPI.getDeliveryFeeSettings(controller)
}

async function updateDeliveryFeeSettings(args: any[], props: any, controller: AbortController): Promise<void> {
  return GDAPI.updateDeliveryFeeSettings(args[0], controller)
}
