import { BasketItem, OrderDetails } from "./GDAPI"

export enum OrderStatus {
  Pending = "Pending",
  BeingPrepared = "Preparing",
  ReadyToCollect = "Ready",
  Collected = "Collected",
  OutForDelivery = "Out for delivery",
  Completed = "Completed",
  Cancelled = "Cancelled",
}

export class OrderHelper {
  static getOrderStatus(order: OrderDetails): OrderStatus {
    if (order.cancelledAt != null) {
      return OrderStatus.Cancelled
    }

    if (order.acceptedAt == null) {
      return OrderStatus.Pending
    }

    if (order.readyAt == null) {
      return OrderStatus.BeingPrepared
    }

    if (order.deliveryAddress != null) {
      if (order.collectedAt == null) {
        return OrderStatus.ReadyToCollect
      }

      if (order.outForDeliveryAt == null) {
        return OrderStatus.Collected
      }

      if (order.deliveredAt == null) {
        return OrderStatus.OutForDelivery
      }
    } else {
      if (order.collectedByCustomerAt == null) {
        return OrderStatus.ReadyToCollect
      }
    }

    return OrderStatus.Completed
  }

  static getItemCount(order: OrderDetails): number {
    return order.basketItems.reduce(
      (quantity, item) => quantity + item.quantity,
      0
    )
  }

  static getItemTotal(item: BasketItem): number {
    if (item.product.offer != null) {
      const offerCount = Math.floor(item.quantity / item.product.offer.quantity)
      const undiscountedQuantity = item.quantity % item.product.offer.quantity

      return (
        offerCount * item.product.offer.price +
        undiscountedQuantity * item.product.price
      )
    } else {
      const optionsTotal =
        item.selectedOptions?.reduce(
          (subtotal, options) =>
            subtotal +
            options.included.reduce(
              (optionsTotal, option) =>
                optionsTotal + option.quantity * option.price,
              0
            ) +
            options.extras.reduce(
              (optionsTotal, option) =>
                optionsTotal + option.quantity * option.price,
              0
            ),
          0
        ) ?? 0

      return item.quantity * (item.product.price + optionsTotal)
    }
  }

  static getReplacedQuantity(item: BasketItem, order: OrderDetails): number {
    return (
      order.basketSubstitutions?.find(
        (substitution) => substitution.id === item.product.id
      )?.quantity ?? 0
    )
  }

  static getRefundedQuantity(item: BasketItem, order: OrderDetails): number {
    if (!order.refunds || order.refunds.length === 0) {
      return 0
    }

    let quantity = 0

    for (const refund of order.refunds) {
      if (!refund.items) {
        // This is a full refund
        return item.quantity
      }

      for (const refundedItem of refund.items) {
        if (refundedItem.id === item.product.id) {
          quantity += refundedItem.quantity
        }
      }
    }

    return quantity
  }

  static getDiscountAmount(order: OrderDetails): number {
    if (order.discount == null) {
      return 0
    }

    const discountEligibleAmount =
      (new Date(order.createdAt).getTime() < 1630450800000
        ? order.deliveryFee
        : 0) +
      order.basketItems
        .filter((item) => !item.excludedFromDiscount)
        .reduce((total, item) => total + OrderHelper.getItemTotal(item), 0)

    const percentage = order.discount.percent / 100
    return Math.floor(discountEligibleAmount * percentage)
  }

  static getRefundAmount(order: OrderDetails): number {
    return (
      order.refunds?.reduce((total, refund) => total + refund.amount, 0) ?? 0
    )
  }

  static getTotal(order: OrderDetails): number {
    let total = order.basketTotal + order.deliveryFee + (order.donation ?? 0)

    if (order.discount != null) {
      const discountAmount = OrderHelper.getDiscountAmount(order)
      total = Math.max(0, total - discountAmount)
    }

    total = Math.max(0, total - OrderHelper.getRefundAmount(order))

    return total
  }
}
