import { FC, useState, useRef } from "react"
import { useAsync } from "react-async"
import { Transition } from "@headlessui/react"

import GettaDeliveryLogo from "../../assets/getta_delivery_logo.png"

import { TextField } from "../common/TextField"
import { useHistory } from "react-router"
import { GDAPI } from "../../lib/GDAPI"

export const ResetPasswordPage: FC = () => {
  const token = new URLSearchParams(window.location.search).get("t")

  const [passwordInput, setPasswordInput] = useState<string>("")
  const passwordFieldRef = useRef<HTMLInputElement>(null)

  const [passwordConfirmationInput, setPasswordConfirmationInput] = useState<string>("")
  const passwordConfirmationFieldRef = useRef<HTMLInputElement>(null)

  const [formError, setFormError] = useState<string>()

  const { error, isPending, run, isResolved } = useAsync({
    deferFn: GDAPI.resetPassword,
    onResolve: () => {
      setPasswordInput("")
      setPasswordConfirmationInput("")
    }
  })

  const history = useHistory()

  if (token == null) {
    history.replace("/")
    return null
  }

  return (
    <div className="flex flex-col items-center bg-white shadow px-4 py-6 flex-1">
      <div className="flex flex-col max-w-sm w-full">
        <img
          className="object-contain h-24 mb-6"
          src={GettaDeliveryLogo}
          alt="Getta Delivery"
        />

        <form
          onSubmit={event => {
            event.preventDefault()
            passwordConfirmationFieldRef.current?.blur()
            passwordFieldRef.current?.blur()
            setFormError(undefined)

            const password = passwordConfirmationInput.trim()

            if (password.length === 0 || password.trim().length === 0) {
              setFormError("Please enter a new password.")
              return
            }

            const passwordConfirmation = passwordInput

            if (passwordConfirmation.length === 0 || passwordConfirmation.trim().length === 0) {
              setFormError("Please confirm your new password.")
              return
            }

            if (password !== passwordConfirmation) {
              setFormError("Your new passwords do not match.")
              return
            }

            run({ token, password })
          }}
        >
          <Transition
            show={error != null || formError != null}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-25"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="py-2 px-4 bg-red-100 text-red-600 text-base font-medium rounded text-center mb-4">
              {error?.message ?? formError}
            </div>
          </Transition>

          <Transition
            show={isResolved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-25"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="py-2 px-4 bg-green-100 text-green-600 text-base font-medium rounded text-center mb-4">
              Your password has been reset. <a href="/log-in" className="underline">Click here</a> to log in.
            </div>
          </Transition>

          <TextField
            ref={passwordFieldRef}
            type="password"
            name="New password"
            value={passwordInput}
            onValueChanged={setPasswordInput}
            required
            className="mb-2"
            disabled={isPending}
            primary
          />

          <TextField
            ref={passwordConfirmationFieldRef}
            type="password"
            name="Confirm new password"
            value={passwordConfirmationInput}
            onValueChanged={setPasswordConfirmationInput}
            required
            disabled={isPending}
            primary
          />

          <input
            type="submit"
            value={isPending ? "Please wait" : "Reset password"}
            className={`
              w-full
              p-2
              rounded-md
              text-lg font-medium 
              shadow
              mt-6
              ${isPending
                  ? "cursor-wait bg-gray-300 text-gray-600"
                  : "cursor-pointer bg-brand-500-lighter text-brand-700 hover:bg-brand-500 hover:text-white"
              }
            `}
            disabled={isPending}
          />
        </form>
      </div>
    </div>
  )
}
