import ReactDOM from "react-dom"

import App from "./App"
import * as serviceWorker from "./serviceWorker"

import "./styles/index.css"
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

ReactDOM.render(<App />, document.getElementById("root"))
serviceWorker.unregister()
