import { Switch } from "@headlessui/react"

type Props = {
  isFeatured: boolean
  onFeaturedChanged: (isFeatured: boolean) => any
  disabled?: boolean
}

export function CategoryFeaturedField({ isFeatured, onFeaturedChanged, disabled }: Props) {
  return (
    <div className="border-t border-b border-blue-100 py-4 flex flex-col">
      <Switch.Group>
        <div className="flex items-center">
          <Switch.Label
            className="flex-1 cursor-pointer pr-4"
          >
            <div className="text-base font-medium text-blue-500">
              Featured category
            </div>
            <div className="text-sm font-regular text-gray-500 leading-4 mt-1">
              Featured categories are shown on the <span className="font-medium">Latest</span> tab in the mobile app.
            </div>
          </Switch.Label>
          <Switch
            as="div"
            checked={isFeatured}
            onChange={(enabled) => {
              if (disabled) {
                return
              }

              onFeaturedChanged(enabled)
            }}
            className={`
              ${isFeatured ? "bg-blue-500" : "bg-gray-200"}
              relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
            `}
          >
            <span
              className={`
                ${isFeatured ? "translate-x-7" : "translate-x-1"}
                inline-block w-6 h-6 bg-white rounded-full
                transform transition ease-in-out duration-200 shadow
              `}
            />
          </Switch>
        </div>
      </Switch.Group>
    </div>
  )
}
