import React, { Fragment, FC, useContext, useEffect, useState, useMemo } from "react"
import { useLocation, useHistory, Switch, Route } from "react-router-dom"

import { Disclosure, Menu, Transition } from "@headlessui/react"
import { MenuIcon, XIcon, UserCircleIcon } from "@heroicons/react/outline"

import GettaDeliveryIcon from "../assets/getta_delivery_icon.png"

import { AppStateContext } from "./AppStateContextProvider"

import LogInPage from "./pages/LogInPage"
import { OrdersPage } from "./pages/orders/OrdersPage"
import { ProductsPage } from "./pages/products/ProductsPage"
import { ReportsPage } from "./pages/reports/ReportsPage"
import { MerchantOnboardingPage } from "./pages/MerchantOnboardingPage"
import { MerchantRegistrationPage } from "./pages/MerchantRegistrationPage"
import { ForgotPasswordPage } from "./pages/forgot-password"
import { ResetPasswordPage } from "./pages/reset-password"
import { ChangePasswordPage } from "./pages/change-password"
import { StaffManagementPage } from "./pages/staff-management/staff-management"
import { SettingsPage } from "./pages/settings/SettingsPage"
// import { MerchantSettingsPage } from "./pages/MerchantSettingsPage"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

type AppPage = {
  path: string
  title: string
  component: JSX.Element
  showInNavBar: boolean
}

const profile = ["Change password", "Log out"]

const AppContainer: FC = () => {
  const { state: { authToken, accountType }, dispatch } = useContext(AppStateContext)

  const availablePages: AppPage[] = useMemo(() => {
    if (authToken != null) {
      if (accountType === "admin") {
        return [
          { path: "/products", title: "Products", component: <ProductsPage />, showInNavBar: true },
          { path: "/orders", title: "Orders", component: <OrdersPage />, showInNavBar: true },
          { path: "/reports", title: "Reports", component: <ReportsPage />, showInNavBar: true },
          { path: "/staff", title: "Staff", component: <StaffManagementPage />, showInNavBar: true },
          { path: "/settings", title: "Settings", component: <SettingsPage />, showInNavBar: true },
          { path: "/change-password", title: "Change password", component: <ChangePasswordPage />, showInNavBar: false },
        ]
      }
  
      if (accountType === "merchant") {
        return [
          { path: "/merchants/onboarding", title: "Onboarding", component: <MerchantOnboardingPage />, showInNavBar: true },
          // { path: "/merchants/settings", title: "Settings", component: <MerchantSettingsPage />, showInNavBar: true },
        ]
      }
    }

    return [
      { path: "/log-in", title: "Log in", component: <LogInPage />, showInNavBar: false },
      { path: "/forgot-password", title: "Forgot password", component: <ForgotPasswordPage />, showInNavBar: false },
      { path: "/reset-password", title: "Reset password", component: <ResetPasswordPage />, showInNavBar: false },
      { path: "/merchants/register", title: "Register", component: <MerchantRegistrationPage />, showInNavBar: false },
    ]
  }, [authToken, accountType])

  const history = useHistory()
  const location = useLocation()
  const [activePage, setActivePage] = useState<AppPage>(availablePages[0])
  const [isHeaderOpen, setHeaderOpen] = useState(false)

  useEffect(() => {
    const page = availablePages.find(page => location.pathname.startsWith(page.path))

    if (page == null) {
      history.replace(authToken === null ? "/log-in" : accountType === "merchant" ? "/merchants/onboarding" : "/products")
      return
    }

    setActivePage(page)
    setHeaderOpen(false)
  }, [location, history, authToken, setActivePage, setHeaderOpen, availablePages, accountType])

  const isLoggedIn = authToken != null

  return (
    <div className="flex flex-col">
      <Disclosure as="nav" className="bg-brand-500">
        {({ open }) => (
          <>
            <div className="px-4">
              <div className="flex items-center justify-between h-16">
                <div className="flex items-center">
                  <div className="flex-shrink-0">
                    <img
                      className="h-8 w-8 rounded-full object-fill"
                      src={GettaDeliveryIcon}
                      alt="Workflow"
                    />
                  </div>
                  <div className="md:hidden block text-white px-3 py-2 text-base font-regular">
                    {activePage.title}
                  </div>
                  <div className="hidden md:block">
                    <div className="ml-10 flex items-baseline space-x-4">
                      {availablePages.filter(page => !page.showInNavBar ? page.path === activePage.path : page.showInNavBar).map(page =>
                        page.path === activePage.path ? (
                          <Fragment key={page.path}>
                            <button
                              onClick={() => history.push(page.path)}
                              className="bg-brand-700 text-white px-3 py-2 rounded-md text-sm font-regular"
                            >
                              {page.title}
                            </button>
                          </Fragment>
                        ) : (
                          <button
                            key={page.path}
                            onClick={() => history.push(page.path)}
                            className="text-white hover:bg-brand-600 px-3 py-2 rounded-md text-sm font-regular"
                          >
                            {page.title}
                          </button>
                        )
                      )}
                    </div>
                  </div>
                </div>
                <div className="hidden md:block">
                  <div className="ml-4 flex items-center md:ml-6">
                    {/* <button className="bg-blue-800 p-1 rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                    {/* Profile dropdown */}
                    {isLoggedIn &&
                      <Menu as="div" className="ml-3 relative z-50">
                        {({ open }) => (
                          <>
                            <div>
                              <Menu.Button className="max-w-xs bg-brand-700 text-gray-50 rounded-full flex items-center text-sm focus:outline-none focus:ring-2 focus:ring-white">
                                <span className="sr-only">Open user menu</span>
                                <UserCircleIcon className="h-8 w-8" />
                              </Menu.Button>
                            </div>
                            <Transition
                              show={open}
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="origin-top-right absolute z-10 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                              >
                                {profile.map((item) => (
                                  <Menu.Item key={item}>
                                    {({ active }) => (
                                      <button
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700 w-full text-left"
                                        )}
                                        onClick={() => {
                                          if (item === "Log out") {
                                            dispatch({ type: "setAuthToken", authToken: null })
                                          }

                                          if (item === "Change password") {
                                            history.push("/change-password")
                                          }
                                        }}
                                      >
                                        {item}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </Menu.Items>
                            </Transition>
                          </>
                        )}
                      </Menu>
                    }
                  </div>
                </div>
                <div className="-mr-2 flex md:hidden">
                  {/* Mobile menu button */}
                  <button
                    onClick={() => setHeaderOpen(!isHeaderOpen)}
                    className="bg-brand-700 inline-flex items-center justify-center p-2 rounded-md text-gray-200 hover:text-white hover:bg-brand-600 focus:outline-none focus:ring-2 focus:ring-white"
                  >
                    <span className="sr-only">Open main menu</span>
                    {isHeaderOpen ? (
                      <XIcon className="block h-6 w-6" aria-hidden="true" />
                    ) : (
                      <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                    )}
                  </button>
                </div>
              </div>
            </div>

            {isHeaderOpen &&
              <Disclosure.Panel className="md:hidden" static>
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                  {availablePages.filter(page => !page.showInNavBar ? page.path === activePage.path : page.showInNavBar).map(page =>
                    page.path === activePage.path ? (
                      <button
                        key={page.path}
                        onClick={() => history.push(page.path)}
                        className="bg-brand-700 text-white block px-3 py-2 rounded-md text-base font-regular"
                      >
                        {page.title}
                      </button>
                    ) : (
                      <button
                        key={page.path}
                        onClick={() => history.push(page.path)}
                        className="text-white hover:bg-brand-600 hover:text-white block px-3 py-2 rounded-md text-base font-regular"
                      >
                        {page.title}
                      </button>
                    )
                  )}
                </div>
                {isLoggedIn &&
                  <div className="pt-4 pb-3 border-t border-brand-600">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        <UserCircleIcon className="h-10 w-10 text-white" />
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">Getta Delivery</div>
                        <div className="text-sm font-regular leading-none text-gray-50">contact@gettadelivery.com</div>
                      </div>
                      {/* <button className="ml-auto bg-blue-800 flex-shrink-0 p-1 rounded-full text-blue-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-blue-800 focus:ring-white">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}
                    </div>
                    <div className="mt-3 px-2 space-y-1">
                      {profile.map((item) => (
                        <button
                          key={item}
                          className="block px-3 py-2 rounded-md text-base font-regular text-white hover:text-white hover:bg-blue-700"
                          onClick={() => {
                            if (item === "Log out") {
                              dispatch({ type: "setAuthToken", authToken: null })
                            }

                            if (item === "Change password") {
                              history.push("/change-password")
                            }
                          }}
                        >
                          {item}
                        </button>
                      ))}
                    </div>
                  </div>
                }
              </Disclosure.Panel>
            }
          </>
        )}
      </Disclosure>

      <main className="flex-1 flex flex-col overflow-y-auto">
        <Switch>
          {availablePages.map(page =>
            <Route key={page.path} path={page.path}>
              {page.component}
            </Route>
          )}
        </Switch>
      </main>
    </div>
  )
}

export default AppContainer
