import { FC, useState, useRef, useContext } from "react"
import { useAsync } from "react-async"
import { Transition } from "@headlessui/react"

import { TextField } from "../common/TextField"
import { GDAPI } from "../../lib/GDAPI"
import { AppStateContext } from "../AppStateContextProvider"

export const ChangePasswordPage: FC = () => {
  const { dispatch } = useContext(AppStateContext)

  const [currentPasswordInput, setCurrentPasswordInput] = useState<string>("")
  const currentPasswordFieldRef = useRef<HTMLInputElement>(null)

  const [newPasswordInput, setNewPasswordInput] = useState<string>("")
  const newPasswordFieldRef = useRef<HTMLInputElement>(null)

  const [passwordConfirmationInput, setPasswordConfirmationInput] = useState<string>("")
  const passwordConfirmationFieldRef = useRef<HTMLInputElement>(null)

  const [formError, setFormError] = useState<string>()

  const { error, isPending, run, isResolved } = useAsync({
    deferFn: GDAPI.changePassword,
    onResolve: (authToken) => {
      dispatch({ type: "setAuthToken", authToken })

      setCurrentPasswordInput("")
      setNewPasswordInput("")
      setPasswordConfirmationInput("")
      setFormError(undefined)
    }
  })

  return (
    <div className="flex flex-col items-center bg-white shadow px-4 py-6 flex-1">
      <div className="flex flex-col max-w-sm w-full">
        <form
          onSubmit={event => {
            event.preventDefault()
            currentPasswordFieldRef.current?.blur()
            passwordConfirmationFieldRef.current?.blur()
            newPasswordFieldRef.current?.blur()
            setFormError(undefined)

            const currentPassword = currentPasswordInput.trim()

            if (currentPassword.length === 0 || currentPassword.trim().length === 0) {
              setFormError("Please enter your current password.")
              return
            }

            const newPassword = passwordConfirmationInput.trim()

            if (newPassword.length === 0 || newPassword.trim().length === 0) {
              setFormError("Please enter a new password.")
              return
            }

            const passwordConfirmation = newPasswordInput

            if (passwordConfirmation.length === 0 || passwordConfirmation.trim().length === 0) {
              setFormError("Please confirm your new password.")
              return
            }

            if (newPassword !== passwordConfirmation) {
              setFormError("Your new passwords do not match.")
              return
            }

            run({ currentPassword, newPassword })
          }}
        >
          <div className="font-medium mb-6 text-xl text-center">Change password</div>

          <Transition
            show={error != null || formError != null}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-25"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="py-2 px-4 bg-red-100 text-red-600 text-base font-medium rounded text-center mb-4">
              {error?.message ?? formError}
            </div>
          </Transition>

          <Transition
            show={isResolved}
            enter="transition-opacity duration-150"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity duration-25"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="py-2 px-4 bg-green-100 text-green-600 text-base font-medium rounded text-center mb-4">
              Your password has been changed.
            </div>
          </Transition>

          <TextField
            ref={currentPasswordFieldRef}
            type="password"
            name="Current password"
            value={currentPasswordInput}
            onValueChanged={setCurrentPasswordInput}
            required
            className="mb-2"
            disabled={isPending}
            primary
          />

          <TextField
            ref={newPasswordFieldRef}
            type="password"
            name="New password"
            value={newPasswordInput}
            onValueChanged={setNewPasswordInput}
            required
            className="mb-2"
            disabled={isPending}
            primary
          />

          <TextField
            ref={passwordConfirmationFieldRef}
            type="password"
            name="Confirm new password"
            value={passwordConfirmationInput}
            onValueChanged={setPasswordConfirmationInput}
            required
            disabled={isPending}
            primary
          />

          <input
            type="submit"
            value={isPending ? "Please wait" : "Change password"}
            className={`
              w-full
              p-2
              rounded-md
              text-lg font-medium 
              shadow
              mt-6
              ${isPending
                  ? "cursor-wait bg-gray-300 text-gray-600"
                  : "cursor-pointer bg-brand-500-lighter text-brand-700 hover:bg-brand-500 hover:text-white"
              }
            `}
            disabled={isPending}
          />
        </form>
      </div>
    </div>
  )
}
