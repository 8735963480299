import { useState } from "react"
import { useAsync } from "react-async"
import { Dialog } from "@headlessui/react"

import { GDAPI, OrderDetails } from "../../../../lib/GDAPI"
import { OrderRefundPicker } from "./OrderRefundPicker"
import { OrderRefund } from "../../../../models/OrderRefund"
import { OrderHelper } from "../../../../lib/OrderHelper"
import { RefundDetails } from "../../../../models/RefundDetails"

export type IssueRefundModalProps = {
  onClose: () => void
  onRefundIssued: (refundDetails: RefundDetails) => void
  order: OrderDetails
}

export const IssueRefundModal: React.FC<IssueRefundModalProps> = ({
  onClose,
  onRefundIssued,
  order,
}) => {
  const [refund, setRefund] = useState<OrderRefund>({ items: [] })
  const [errorMessage, setErrorMessage] = useState<string>()
  const [refundConfirmationValue, setRefundConfirmationValue] = useState("")
  const isRefundConfirmed =
    refundConfirmationValue.trim().toLowerCase() === order.id.toLowerCase()

  const issueRefundRequest = useAsync<RefundDetails>({
    deferFn: issueRefund,
    onResolve: onRefundIssued,
    onReject(error) {
      setErrorMessage(error.message)
    },
  })

  return (
    <Dialog open onClose={() => {}} className="fixed z-10 inset-0">
      <div className="flex flex-col items-center justify-center h-full">
        <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />

        <div
          className="w-full flex flex-col overflow-y-auto z-20 bg-white md:shadow-lg rounded-lg p-6"
          style={{ maxWidth: "544px" }}
        >
          <div className="flex flex-col gap-y-4">
            <div className="flex flex-row justify-between">
              <Dialog.Title className="font-medium">Issue refund</Dialog.Title>

              <button
                className="text-red-500 font-medium hover:text-gray-500"
                onClick={issueRefundRequest.isPending ? undefined : onClose}
              >
                Cancel
              </button>
            </div>

            <OrderRefundPicker
              order={order}
              value={refund}
              onChange={setRefund}
              disabled={issueRefundRequest.isPending}
            />

            <button
              className={"font-medium rounded-md p-2 bg-blue-500 text-white"}
              onClick={() => {
                setRefund({
                  items: order.basketItems.map((item) => ({
                    id: item.product.id,
                    quantity:
                      item.quantity -
                      OrderHelper.getRefundedQuantity(item, order),
                  })),
                  refundDeliveryFee: true,
                })
              }}
              disabled={issueRefundRequest.isPending}
            >
              Select all for refund
            </button>

            <div className="flex flex-col gap-y-3">
              <div className="flex flex-col">
                <div className="font-medium">Confirm refund</div>
                <p>
                  Please enter the order ID ({order.id}) to confirm this refund.
                </p>
              </div>

              <input
                className="border border-gray-300 rounded-md p-2"
                type="text"
                value={refundConfirmationValue}
                placeholder={order.id}
                onChange={(e) =>
                  setRefundConfirmationValue(e.target.value.toUpperCase())
                }
                disabled={issueRefundRequest.isPending}
              />

              {errorMessage != null && (
                <p className="font-medium text-red-600">{errorMessage}</p>
              )}
            </div>

            <button
              className={`font-medium rounded-md p-2 ${
                issueRefundRequest.isPending || !isRefundConfirmed
                  ? "bg-gray-200 text-gray-500 opacity-75"
                  : "bg-green-500 text-white"
              }`}
              onClick={() => {
                setErrorMessage(undefined)
                issueRefundRequest.run(order.id, refund)
              }}
              disabled={issueRefundRequest.isPending || !isRefundConfirmed}
            >
              {issueRefundRequest.isPending ? "Refunding..." : "Refund"}
            </button>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

async function issueRefund(
  args: unknown[],
  props: unknown,
  controller: AbortController
): Promise<RefundDetails> {
  const [id, refund] = args as [string, OrderRefund]
  return GDAPI.issueRefund(id, refund, controller)
}
