import { Listbox } from "@headlessui/react"
import { SelectorIcon } from "@heroicons/react/solid"
import { useMemo } from "react"
import { useAsync } from "react-async"

import { GDAPI, MerchantSummary } from "../../../../../lib/GDAPI"

type MerchantOptionProps = {
  merchant: MerchantSummary
}

function MerchantOption({ merchant }: MerchantOptionProps) {
  return (
    <Listbox.Option value={merchant.id}>
      {({ active, selected }) => (
        <div
          className={`
            text-base
            ${selected ? "font-medium text-blue-500" : "font-regular"}
            pr-2 py-2
            border-b border-gray-300
            bg-white hover:bg-blue-50 ${active ? "bg-blue-50" : ""}
            cursor-pointer
          `}
          style={{ paddingLeft: 8 }}
        >
          {merchant.name}
        </div>
      )}
    </Listbox.Option>
  )
}

type MerchantPickerProps = {
  className?: string
  label: string
  selectedMerchantId: number | null
  onMerchantSelected: (merchantId: number | null) => void
  required?: boolean
}

async function getMerchants(props: any, controller: AbortController): Promise<MerchantSummary[]> {
  return GDAPI.getMerchants(props, controller)
}

export function MerchantPicker({
  className,
  label,
  selectedMerchantId,
  onMerchantSelected,
  required
}: MerchantPickerProps) {
  const { data: merchants } = useAsync({ promiseFn: getMerchants })

  const options = useMemo(() => {
    if (merchants == null) {
      return []
    }

    return merchants.map(merchant => <MerchantOption key={merchant.id} merchant={merchant} />)
  }, [merchants])

  const selectedMerchant = useMemo(() => {
    if (selectedMerchantId == null || merchants == null) {
      return undefined
    }

    return merchants.find(merchant => merchant.id === selectedMerchantId)
  }, [selectedMerchantId, merchants])

  return (
    <div className={`${className}`}>
      <Listbox
        value={selectedMerchantId}
        onChange={onMerchantSelected}
      >
        <div className={`flex flex-col`}>
          <Listbox.Label className="text-base font-medium text-blue-500 mb-2">
            {label}
          </Listbox.Label>
          <Listbox.Button
            className={`border border-blue-500 rounded py-2 shadow flex flex-row items-center ${selectedMerchant != null ? "text-black" : "text-gray-400"}`}
          >
            <div className="flex-1 text-left mx-2">
              {selectedMerchant?.name ?? "None"}
            </div>
            <SelectorIcon className="w-5 h-5 text-blue-500 mr-1" />
          </Listbox.Button>
        </div>
        <Listbox.Options
          className="flex flex-col z-10 max-h-40 md:max-h-60 overflow-y-auto bg-white rounded border border-blue-500 shadow-md"
        >
          {!required &&
            <Listbox.Option
              value={null}
            >
              {({ active, selected }) => (
                <div className={`text-base font-regular text-gray-400 p-2 border-b border-gray-300 hover:bg-blue-50 cursor-pointer`}>
                  None
                </div>
              )}
            </Listbox.Option>
          }
          {options}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}
