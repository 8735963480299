import { TimeRange } from "../../../lib/GDAPI"
import { DayTimeRangeField } from "./DayTimeRangeField"

export type DailyAvailabilityFieldProps = {
  availability: TimeRange[]
  onAvailabilityChanged: (availability: TimeRange[]) => void
  disabled?: boolean
}

export const DailyAvailabilityField: React.FC<DailyAvailabilityFieldProps> = ({ availability, onAvailabilityChanged, disabled }) => {
  return (
    <div className="flex flex-col">
      {availability.map((dayAvailability, index) => (
        <DayTimeRangeField
          key={index}
          day={DAYS_OF_WEEK[index]}
          timeRange={dayAvailability}
          onTimeRangeChanged={newDayAvailability => {
            const newAvailability = [...availability]
            newAvailability[index] = newDayAvailability
            onAvailabilityChanged(newAvailability)
          }}
          disabled={disabled}
        />
      ))}
    </div>
  )
}

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday"
]
