import { Switch, Transition } from "@headlessui/react"
import { forwardRef, useEffect, useRef, useState } from "react"
import _ from "lodash"

import { usePrevious } from "../../../../../hooks/UsePrevious"

type InputProps = {
  value: string
  onValueChanged: (value: string) => any
  disabled?: boolean
  onSubmit?: () => any
  className?: string
}

const ProductMaxQuantityInput = forwardRef<HTMLInputElement, InputProps>(({
  value, onValueChanged, disabled, onSubmit, className
}, ref) => {
  const parsedValue = parseInt(value)
  const hasError = isNaN(parsedValue) || parsedValue < 1

  return (
    <input
      type="number"
      className={`
        ${className}
        w-16 text-center
        appearance-none border rounded py-2 text-base
        text-black
        shadow
        ${disabled 
          ? "border-gray-500 opacity-50" 
          : hasError
            ? "border-red-500"
            : "border-blue-500"
        }
        ${hasError ? "bg-red-100" : "bg-white"}
      `}
      value={value}
      onChange={event => {
        onValueChanged(event.target.value)
      }}
      onKeyDown={e => {
        if (e.key === "Enter") {
          e.preventDefault()
          
          if (onSubmit) {
            onSubmit()
          }
        }
      }}
      disabled={disabled}
      required
      min={1}
      ref={ref}
    />
  )
})

type Props = {
  maxQuantity?: number
  onMaxQuantityChanged: (maxQuantity?: number) => any
  disabled?: boolean
}

export function ProductMaxQuantityField({ maxQuantity, onMaxQuantityChanged, disabled }: Props) {
  const quantityInput = useRef<HTMLInputElement>(null)
  const [quantityValue, setQuantityValue] = useState("")
  const isEnabled = maxQuantity != null

  const previousMaxQuantity = usePrevious(maxQuantity)

  useEffect(() => {
    if (maxQuantity == null || _.isEqual(maxQuantity, previousMaxQuantity)) {
      return
    }

    setQuantityValue(maxQuantity?.toString() ?? "")
  }, [maxQuantity, previousMaxQuantity, setQuantityValue])

  useEffect(() => {
    const quantity = parseInt(quantityValue)

    if (isNaN(quantity) || quantity <= 0) {
      return
    }

    onMaxQuantityChanged(quantity)
  }, [quantityValue, onMaxQuantityChanged])

  return (
    <div className="border-t border-b border-blue-100 py-4 flex flex-col">
      <Switch.Group>
        <div className="flex items-center">
          <Switch.Label
            className="flex-1 cursor-pointer pr-4"
          >
            <div className="text-base font-medium text-blue-500">
              Limited order quantity
            </div>
            <div className="text-sm font-regular text-gray-500 leading-4 mt-1">
              Limit how many of these products can be purchased in a single order.
            </div>
          </Switch.Label>
          <Switch
            as="div"
            checked={isEnabled}
            onChange={(enabled) => {
              if (disabled) {
                return
              }

              if (maxQuantity != null) {
                onMaxQuantityChanged(undefined)
              } else {
                const quantity = parseInt(quantityValue)
                onMaxQuantityChanged(isNaN(quantity) ? 1 : quantity)
              }
            }}
            className={`
              ${isEnabled ? "bg-blue-500" : "bg-gray-200"}
              relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
            `}
          >
            <span
              className={`
                ${isEnabled ? "translate-x-7" : "translate-x-1"}
                inline-block w-6 h-6 bg-white rounded-full
                transform transition ease-in-out duration-200 shadow
              `}
            />
          </Switch>
        </div>
      </Switch.Group>
      <Transition
        show={isEnabled}
        enter="transition-opacity ease-in-out duration-200"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity ease-in-out duration-200"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="flex flex-col">
          <div className="flex flex-row my-4">
            <div className="flex flex-1 flex-col">
              <label
                htmlFor="from"
                className="text-base font-medium text-blue-500 mb-1"
              >
                Max per order
              </label>
              <div className="flex flex-row items-center">
                <ProductMaxQuantityInput
                  value={quantityValue}
                  onValueChanged={setQuantityValue}
                  disabled={disabled}
                  ref={quantityInput}
                  onSubmit={() => {
                    quantityInput.current?.focus()
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </div>
  )
}
