import { ArrowSmRightIcon, PencilIcon } from "@heroicons/react/solid"
import { ProductCategorySummary } from "../../../lib/GDAPI"
import { CategoryAvailabilityLabel } from "./dialogs/category/CategoryAvailabilityLabel"

type Props = {
  category: ProductCategorySummary
  onEditPressed: (category: ProductCategorySummary) => void
  onViewPressed: (category: ProductCategorySummary) => void
}

export function ProductCategoryItem({ category, onEditPressed, onViewPressed }: Props) {
  const products = category.products > 0
    ? category.products === 1
      ? "1 product"
      : `${category.products} products`
    : ``

  const offers = category.offers === 1 ? "1 offer" : `${category.offers} offers`
  
  const subcategories = category.subcategories > 0
    ? category.subcategories === 1
      ? `1 category`
      : `${category.subcategories} categories`
    : ``

  return (
    <div
      key={category.id}
      className="border-b border-gray-200 bg-white w-full text-left p-4 flex flex-row items-center"
    >
      <div className="w-10 h-10 md:w-16 md:h-16 rounded-lg">
        {category.image != null ? (
          <img
            src={category.image}
            className="w-10 h-10 md:w-16 md:h-16 object-contain rounded-lg"
            alt={category.name}
          />
        ) : (
          <div className="w-full h-full rounded-md border-2 border-dashed border-blue-200 flex justify-center items-center" />
        )}
      </div>
      <div className="flex-1 flex flex-col ml-3 mr-4">
        <div className="flex flex-col">
          <div className="text-base font-medium text-gray-800 leading-4 break-all">
            {category.name} {category.isFeatured && <span className="text-xs text-white bg-red-600 rounded px-1 py-0.5">Featured</span>} {category.isHidden && <span className="text-xs text-white bg-purple-600 rounded px-1 py-0.5">Hidden</span>}
          </div>
          {(category.products > 0 || category.subcategories > 0) &&
            <>
              {(category.id === 9_000_000_000 || category.id === 9_000_000_001) ? (
                <div className="text-sm font-normal text-gray-600 leading-4 mt-1">
                  {offers}
                </div>
              ) : (
                <div className="text-sm font-normal text-gray-600 leading-4 mt-1">
                  {subcategories} {category.subcategories > 0 && category.products > 0 && "-"} {products} {category.offers > 0 && ` - ${offers}`}
                </div>
              )}
            </>
          }
        </div>
        {category.availableBetween != null &&
          <CategoryAvailabilityLabel
            availableBetween={category.availableBetween}
            className="mt-2"
          />
        }
      </div>
      <button
        className="bg-yellow-200 p-2 rounded-full shadow-sm flex flex-row items-center self-center mr-4"
        onClick={() => onEditPressed(category)}
      >
        <PencilIcon className="h-5 w-5 text-yellow-800" />
      </button>
      <button
        className="bg-blue-200 p-2 rounded-full shadow-sm flex flex-row items-center self-center"
        onClick={() => onViewPressed(category)}
      >
        <ArrowSmRightIcon className="h-5 w-5 text-blue-800" aria-hidden="true" />
      </button>
    </div>
  )
}
