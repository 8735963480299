import { DeliveryFeeSettings } from "../../../../models/DeliveryFeeSettings"

export type DeliveryFeeSettingsEditorProps = {
  deliveryFeeSettings: DeliveryFeeSettings
  onChange: (deliveryFeeSettings: DeliveryFeeSettings) => void
  disabled?: boolean
}

export const DeliveryFeeSettingsEditor: React.FC<DeliveryFeeSettingsEditorProps> = ({ deliveryFeeSettings, onChange, disabled }) => {
  const { baseFee, maxMiles, costPerMile, includedMiles: milesIncluded } = deliveryFeeSettings

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row">
        <div className="flex flex-col w-full md:w-1/2 md:mr-4">
          <label className="text-sm font-medium text-gray-700">Base fee (pence)</label>

          <input
            className="w-full px-3 py-2 mt-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
            type="number"
            value={baseFee}
            onChange={e => onChange({ ...deliveryFeeSettings, baseFee: parseInt(e.target.value) })}
            disabled={disabled}
            min={0}
          />
        </div>

        <div className="flex flex-col w-full mt-4 md:mt-0 md:w-1/2 md:ml-4">
          <label className="text-sm font-medium text-gray-700">Max miles</label>

          <input
            className="w-full px-3 py-2 mt-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
            type="number"
            value={maxMiles}
            onChange={e => onChange({ ...deliveryFeeSettings, maxMiles: parseInt(e.target.value) })}
            disabled={disabled}
            min={1}
          />
        </div>
      </div>

      <div className="flex flex-col md:flex-row mt-4">
        <div className="flex flex-col w-full md:w-1/2 md:mr-4">
          <label className="text-sm font-medium text-gray-700">Miles included in base fee</label>

          <input
            className="w-full px-3 py-2 mt-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
            type="number"
            value={milesIncluded}
            onChange={e => onChange({ ...deliveryFeeSettings, includedMiles: parseInt(e.target.value) })}
            disabled={disabled}
            min={1}
          />
        </div>

        <div className="flex flex-col w-full mt-4 md:mt-0 md:w-1/2 md:ml-4">
          <label className="text-sm font-medium text-gray-700">Cost per mile (pence)</label>

          <input
            className="w-full px-3 py-2 mt-1 text-sm border rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:border-transparent"
            type="number"
            value={costPerMile}
            onChange={e => onChange({ ...deliveryFeeSettings, costPerMile: parseInt(e.target.value) })}
            disabled={disabled}
            min={0}
          />
        </div>
      </div>
    </div>
  )
}
