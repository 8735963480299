import { CSSProperties, forwardRef } from "react"

export type TextFieldProps = {
  name: string
  value: string
  onValueChanged: (value: string) => void
  type?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  className?: string
  onSubmit?: () => any
  autoComplete?: string
  min?: string | number
  primary?: boolean
  hideLabel?: boolean
  style?: CSSProperties
}

export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(({
  name,
  value,
  onValueChanged,
  type,
  placeholder,
  disabled,
  required,
  className,
  onSubmit,
  autoComplete,
  min,
  primary,
  hideLabel,
  style
}, ref) => {
  return (
    <div className={`flex flex-col ${className}`} style={style}>
      {!hideLabel &&
        <label
          htmlFor={name}
          className={`text-base font-medium ${primary ? "text-brand-600" : "text-blue-500"} mb-2 w-full`}
        >
          {name}
        </label>
      }
      <input
        id={name}
        type={type ?? "text"}
        className={`
          appearance-none border rounded p-2 text-base
          text-black
          shadow
          ${disabled 
            ? "border-gray-500 opacity-50" 
            : primary
              ? "border-brand-600"
              : "border-blue-500"
          }
          focus:outline-none focus:ring-2 focus:ring-${primary ? "brand" : "blue-500"}
          w-full
        `}
        value={value}
        onChange={event => onValueChanged(event.target.value)}
        onKeyDown={e => {
          if (e.key === "Enter") {
            e.preventDefault()
            
            if (onSubmit) {
              onSubmit()
            }
          }
        }}
        placeholder={placeholder}
        disabled={disabled}
        required={required}
        ref={ref}
        autoComplete={autoComplete}
        min={min}
      />
    </div>
  )
})
