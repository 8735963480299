import { StarIcon } from "@heroicons/react/solid"

import { ProductOfferSummary } from "../../../../../lib/GDAPI"

type Props = {
  offer: ProductOfferSummary
  className?: string
}

function formatPrice(price: number) {
  if (price < 100) {
    return `${price}p`
  }

  if (price % 100 === 0) {
    return `£${price / 100}`
  }

  return `£${(price / 100).toFixed(2)}`
}

export function ProductOfferLabel({ offer, className }: Props) {
  const { quantity, price, isFeatured } = offer

  const formattedPrice = formatPrice(price)
  const label = offer.quantity === 1
    ? `Only ${formattedPrice}`
    : `${quantity} for ${formattedPrice}`

  return (
    <div className="flex flex-row items-center">
      <div className={`px-2 py-0.5 self-start rounded-lg bg-red-600 flex flex-row items-center ${className}`}>
        <div className="text-base text-white font-semibold">
          {label}
        </div>
      </div>
      {isFeatured &&
        <StarIcon className="w-6 h-6 text-yellow-400 ml-0.5" />
      }
    </div>
  )
}
