import { useRef } from "react"

import { TimeRange } from "../../../lib/GDAPI"
import { TimePartInput } from "./TimePartInput"
import { isAfter } from "../../../lib/TimeUtils"
import { isSameTime } from "../../../lib/TimeUtils"

export type DayTimeRangeFieldProps = {
  day: string
  timeRange: TimeRange
  onTimeRangeChanged: (timeRange: TimeRange) => any
  disabled?: boolean
}

export const DayTimeRangeField: React.FC<DayTimeRangeFieldProps> = ({ day, timeRange, onTimeRangeChanged, disabled }) => {
  const fromHourInput = useRef<HTMLInputElement>(null)
  const fromMinuteInput = useRef<HTMLInputElement>(null)

  const untilHourInput = useRef<HTMLInputElement>(null)
  const untilMinuteInput = useRef<HTMLInputElement>(null)

  const isValidTimeRange = isSameTime(timeRange.start, timeRange.end) || isAfter(timeRange.end, timeRange.start)

  return (
    <div className="border-t border-b border-blue-100 py-2 flex flex-col">
      <div className="flex flex-row items-center">
        <div className="flex flex-1 font-medium text-base">
          {day}
        </div>

        <div className="flex-1 flex flex-col mr-4">
          <label
            htmlFor="from"
            className="text-sm font-medium text-blue-500"
          >
            From
          </label>

          <div className="flex flex-row items-center">
            <TimePartInput
              value={timeRange.start[0].toString()}
              onValueChanged={(value) => 
                onTimeRangeChanged({
                  start: [parseInt(value), timeRange.start[1]],
                  end: timeRange.end
                })
              }
              max={23}
              disabled={disabled}
              ref={fromHourInput}
              onSubmit={() => {
                fromMinuteInput.current?.focus()
              }}
              validInputOnly
            />

            <div className="text-xl font-medium text-black px-1 h-full leading-9">:</div>

            <TimePartInput
              value={timeRange.start[1].toString()}
              onValueChanged={(value) =>
                onTimeRangeChanged({
                  start: [timeRange.start[0], parseInt(value)],
                  end: timeRange.end
                })
              }
              max={59}
              disabled={disabled}
              ref={fromMinuteInput}
              onSubmit={() => {
                untilHourInput.current?.focus()
              }}
              validInputOnly
            />
          </div>
        </div>

        <div className="flex flex-col">
          <label
            htmlFor="from"
            className="text-sm font-medium text-blue-500"
          >
            Until
          </label>

          <div className="flex flex-row items-center">
            <TimePartInput
              value={timeRange.end[0].toString()}
              onValueChanged={(value) =>
                onTimeRangeChanged({
                  start: timeRange.start,
                  end: [parseInt(value), timeRange.end[1]]
                })
              }
              max={23}
              disabled={disabled}
              ref={untilHourInput}
              onSubmit={() => {
                untilMinuteInput.current?.focus()
              }}
              validInputOnly
            />

            <div className="text-xl font-medium text-black px-1 h-full leading-9">:</div>
            
            <TimePartInput
              value={timeRange.end[1].toString()}
              onValueChanged={(value) =>
                onTimeRangeChanged({
                  start: timeRange.start,
                  end: [timeRange.end[0], parseInt(value)]
                })
              }
              max={59}
              disabled={disabled}
              ref={untilMinuteInput}
              onSubmit={() => {
                untilMinuteInput.current?.blur()
              }}
              validInputOnly
            />
          </div>
        </div>
      </div>

      {!isValidTimeRange && (
        <div className="self-end text-red-500 text-sm font-medium mt-2">
          Invalid time range. "From" must be before "Until".
        </div>
      )}
    </div>
  )
}