import React, { useCallback, useState } from "react"
import { useAsync } from "react-async"
import { GDAPI } from "../../../lib/GDAPI"

import { AddShopStaffModal } from "./modals/add-shop-staff-modal"
import { ConfirmDeleteShopStaffModal } from "./modals/confirm-delete-shop-staff-modal"

export const ShopStaffManager: React.FC = () => {
  const [isShopStaffModalOpen, setShopStaffModalOpen] = useState(false)
  const onShopStaffModalClose = useCallback(() => setShopStaffModalOpen(false), [setShopStaffModalOpen])
  const onAddStaffMemberClick = useCallback(() => setShopStaffModalOpen(true), [setShopStaffModalOpen])

  const [staffMemberForDeletion, setStaffMemberForDeletion] = useState<string>()

  const { data: staffMembers, reload: reloadStaffMembers, isPending } = useAsync({ promiseFn: getShopStaff })

  if (!staffMembers) {
    return null
  }

  const staffMemberRows = staffMembers.map((staffMember) => (
    <tr key={staffMember} className={styles.staffTableRow}>
      <td className={styles.staffTableMemberCell}>{staffMember}</td>
      <td className={styles.staffTableActionsCell}>
        <span className={styles.actionsContainer}>
          <button
            className={styles.deleteAction}
            onClick={() => setStaffMemberForDeletion(staffMember)}
            disabled={isPending}
          >
            Delete
          </button>
        </span>
      </td>
    </tr>
  ))

  return (
    <>
      {isShopStaffModalOpen && (
        <AddShopStaffModal
          visible
          onClose={onShopStaffModalClose}
          onStaffMemberAdded={() => {
            reloadStaffMembers()
            setShopStaffModalOpen(false)
          }}
          existingStaffMembers={staffMembers}
        />
      )}

      {staffMemberForDeletion != null && (
        <ConfirmDeleteShopStaffModal
          visible
          onClose={() => setStaffMemberForDeletion(undefined)}
          onStaffMemberDeleted={() => {
            reloadStaffMembers()
            setStaffMemberForDeletion(undefined)
          }}
          staffMember={staffMemberForDeletion}
          existingStaffMembers={staffMembers}
        />
      )}

      <div className={styles.container}>
        <table className={styles.staffTable}>
          <thead>
            <tr className={styles.staffTableHeaderRow}>
              <th className={styles.staffTableHeaderCell} colSpan={2}>
                <span className={styles.staffTableHeadingContainer}>
                  <span>Staff members</span>
                  <span
                    className={styles.addStaffMemberAction}
                    onClick={onAddStaffMemberClick}
                  >
                    Add shop staff
                  </span>
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            {staffMemberRows}
          </tbody>
        </table>
      </div>
    </>
  )
}

const styles = {
  container: "flex flex-col py-4",
  heading: "font-medium text-lg",
  staffTable: "table-auto w-full",
  staffTableHeadingContainer: "flex flex-row items-center justify-between font-medium",
  addStaffMemberAction: "font-normal cursor-pointer hover:underline py-1.5 px-3 rounded-md bg-brand-500 text-white",
  staffTableHeaderRow: "border-b border-gray-100 h-14",
  staffTableHeaderCell: "px-4",
  staffTableRow: "border-b border-gray-100 h-14 hover:bg-gray-100",
  staffTableMemberCell: "pl-4",
  staffTableActionsCell: "pr-4",
  actionsContainer: "flex flex-row items-center justify-end gap-x-8",
  deleteAction: "font-normal cursor-pointer hover:underline py-1.5 px-3 rounded-md bg-red-500 text-white"
}

async function getShopStaff(props: any, controller: AbortController): Promise<string[]> {
  return GDAPI.getShopStaff(controller)
}
